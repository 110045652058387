import {
  Box,
  Divider,
  Flex,
  IconButton,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { pickByMode } from "../../utils/theme";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Profile from "./components/Profile";
import CustomerCalls from "./components/Calls";
import CallActions from "./components/CallActions";
import { useState } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { firestoreDb } from "../../firebase";

const CustomerDetails = ({ selectedCustomer, setSelectedCustomer }) => {
  const { colorMode } = useColorMode();
  const [currentCall, setCurrentCall] = useState<string>("");
  const [realTimeConv, setRealTimeConv] = useState<any>([]);
  const { campaignId } = useParams<{ campaignId: string }>();
  const toast = useToast();

  async function deleteCustomer() {
    if (!selectedCustomer) {
      return;
    }
    const customerDocRef = doc(
      firestoreDb,
      "campaigns",
      campaignId!,
      "customers",
      selectedCustomer.id
    );
    await deleteDoc(customerDocRef);
    setSelectedCustomer(undefined);
    toast({
      title: "Customer deleted",
      description: "Customer deleted successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  return (
    <Box
      padding="3%"
      w="100%"
      boxShadow="lg"
      border={`1px solid  ${pickByMode(colorMode, "#e8e8e8", "#2e2e2e")}`}
      borderRadius="10px"
    >
      <Flex>
        <IconButton
          aria-label="Back"
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={() => setSelectedCustomer(undefined)}
        />
        <Text ml={5} fontWeight="600" fontSize="2xl">
          {selectedCustomer.name}
        </Text>
      </Flex>
      <Divider my={4} />
      <Profile
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />
      <Divider marginTop="3%" marginBottom="2%" />
      <CustomerCalls
        selectedCustomer={selectedCustomer}
        currentCall={currentCall}
        setCurrentCall={setCurrentCall}
        setRealTimeConv={setRealTimeConv}
      />
      <Divider marginTop="2%" marginBottom="2%" />
      <CallActions
        currentCall={currentCall}
        deleteCustomer={deleteCustomer}
        realTimeConv={realTimeConv}
        selectedCustomer={selectedCustomer}
        setCurrentCall={setCurrentCall}
      />
    </Box>
  );
};

export default CustomerDetails;
