import { Center, FormLabel, Select, Stack } from "@chakra-ui/react";
import { voicesList } from "../utils/others";

const SelectVoice = ({ selected, onSelect }) => {
  const onChange = (e) => {
    onSelect(e.target.value);
  };

  const chosenVoice = voicesList.find((voice) => voice.id === selected);

  return (
    <Stack mt="5">
      <FormLabel>Representative's voice</FormLabel>
      <Select
        value={chosenVoice?.id}
        onChange={onChange}
        placeholder="Pick voice"
      >
        {voicesList.map((voice) => (
          <option key={voice.id} value={voice.id}>
            {voice.label}
          </option>
        ))}
      </Select>
      {chosenVoice?.previewUrl && (
        <Center marginTop="4%">
          <audio key={selected} controls>
            <source src={"/" + chosenVoice?.previewUrl} type="audio/mpeg" />
          </audio>
        </Center>
      )}
    </Stack>
  );
};

export default SelectVoice;
