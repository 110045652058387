import { extendTheme, type ThemeConfig,  } from '@chakra-ui/react';


const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
}


const theme = extendTheme({ 
  config, 
  colors: {
    brand: {
      primary: '#057734',
      primary_23: 'rgba(5, 119, 52, 0.23)',
    },
  },
  styles: {
    global: props => {
      return ({
        body: {
          bg: props.colorMode === "light" ? "rgba(239, 237, 240, 0.3)" : "#1B1D20",
        }
      })
    }
  }
});


export const pickByMode = (colorMode: string, light: string, dark: string) => {
  return colorMode === "light" ? light : dark;
}

export default theme;