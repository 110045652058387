import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { fetcher } from "../../utils/api/fetcher";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../utils/queryClient";

interface NewAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId?: string;
}

const NewAgentModal: React.FC<NewAgentModalProps> = ({
  isOpen,
  onClose,
  campaignId,
}) => {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const inviteAgentMutation = useMutation({
    mutationFn: async (data: { email: string }) => {
      const response = await fetcher("/agents/invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: data.email, campaign_id: campaignId }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error?.detail || "Failed to invite the user");
      }

      return response.json();
    },
    onSuccess: (data) => {
      toast({
        title: "User invited.",
        description: "The user has been successfully invited.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      queryClient.setQueryData(["agents", campaignId], (oldData: any) => {
        const newData = [
          ...oldData,
          { email: data.email, invitedAt: dayjs().format("YYYY-MM-DD") },
        ];
        return newData.sort((a, b) => a.email.localeCompare(b.email));
      });
      reset();
      onClose();
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = (data) => {
    inviteAgentMutation.mutate(data);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      closeOnEsc={!inviteAgentMutation.isPending}
      closeOnOverlayClick={!inviteAgentMutation.isPending}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite New User</ModalHeader>
        <ModalCloseButton isDisabled={inviteAgentMutation.isPending} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={!!errors.email}>
              <Input
                type="email"
                placeholder="Email address"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              <FormErrorMessage>{errors?.email?.message as string}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={inviteAgentMutation.isPending}
              colorScheme="green"
              mr={3}
              type="submit"
            >
              Send Invite
            </Button>
            <Button
              variant="ghost"
              onClick={onClose}
              isDisabled={inviteAgentMutation.isPending}
            >
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default NewAgentModal;
