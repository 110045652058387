import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  Divider,
  Input,
  Badge,
  IconButton,
  Wrap,
  WrapItem,
  useToast,
  Box,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase";
import fetchGetEmailFromUid from "../helpers/fetchGetEmailFromUid";
import { doc, onSnapshot, writeBatch } from "firebase/firestore";
import fetchGetUidFromEmail from "../helpers/fetchGetUidFromEmail";
import { CampaignDataInterface } from "../utils/interfaces";

interface ManageAccountWindowProps {
  openManageAccount: boolean;
  setOpenManageAccount: (open: boolean) => void;
  auth: any;
  allCampaigns: { [key: string]: CampaignDataInterface } | null;
}

const EmailBadge: React.FC<{email: string, onRemove: () => void }> = ({ email, onRemove }) => {
  const badgeBg = useColorModeValue('blue.500', 'blue.200');
  const textColor = useColorModeValue('white', 'gray.800');
  const buttonColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <WrapItem>
      <Badge px="3" py="1" borderRadius="lg" bg={badgeBg} color={textColor} boxShadow="sm">
        <Flex align="center">
          <Text fontSize="sm" mr="2">{email}</Text>
          <IconButton
            colorScheme={buttonColor}
            size="xs"
            variant="ghost"
            aria-label="Remove user"
            icon={<CloseIcon />}
            onClick={onRemove}
          />
        </Flex>
      </Badge>
    </WrapItem>
  );
};

export default function ManageAccountWindow({ openManageAccount, setOpenManageAccount, auth, allCampaigns }: ManageAccountWindowProps) {
  const [allowedUsers, setAllowedUsers] = useState<string[]>([]);
  const [newUser, setNewUser] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [masterUids, setMasterUids] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState<string>("");
  const [allowedRegisterEmails, setAllowedRegisterEmails] = useState<string[]>([]);
  const [companySelected, setCompanySelected] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    const uniqueUids = allCampaigns 
    ? Array.from(new Set(Object.keys(allCampaigns)
        .filter(campaignId => allCampaigns[campaignId].createdBy === auth.currentUser.uid)
        .flatMap(campaignId => allCampaigns[campaignId].allowedAccess)))
    : [];

    const masterAccessRef = doc(firestoreDb, "utils", "masterAccess");
    const unsubscribe = onSnapshot(masterAccessRef, (docData) => {
      if (docData.exists()) {
        const mUids = docData.data()!.uids;
        setMasterUids(mUids);
        const filteredUids = uniqueUids.filter(uid => (!mUids.includes(uid) && uid !== auth.currentUser.uid));
        fetchGetEmailFromUid(filteredUids).then((response) => {
          setAllowedUsers(response.emails);
        });
        if (mUids.includes(auth.currentUser.uid)) {
          const allowedRegisterEmailsRef = doc(firestoreDb, "utils", "allowedRegistration")
          const unsubscribe = onSnapshot(allowedRegisterEmailsRef, (docData) => {
            if (docData.exists()) {
              setAllowedRegisterEmails(docData.data().emails);
            }
          })
          return () => unsubscribe();
        }
      }
    }
    );
    return () => unsubscribe();
  }, []);


  const changePermissions = async () => {
    const allowedUsersUid = await fetchGetUidFromEmail(allowedUsers);
    allowedUsersUid.uids.push(...masterUids);
    if (!allowedUsersUid.uids.includes(auth.currentUser.uid)) {
      allowedUsersUid.uids.push(auth.currentUser.uid);
    }
    const batch = writeBatch(firestoreDb);
    if (Array.isArray(allCampaigns)) {
      const filteredCampaigns = Object.keys(allCampaigns)
        .filter((campaignId) => allCampaigns[campaignId].dealName === companySelected && allCampaigns[campaignId].createdBy === auth.currentUser.uid);
      filteredCampaigns.forEach((campaignId) => {
        const campaignRef = doc(firestoreDb, "campaigns", campaignId);
        batch.update(campaignRef, { allowedAccess: allowedUsersUid.uids });
      });
    }
    if (masterUids.includes(auth.currentUser.uid)) {
      const allowedRegistrationRef = doc(firestoreDb, "utils", "allowedRegistration");
      batch.update(allowedRegistrationRef, { emails: allowedRegisterEmails});
    }
    await batch.commit();
    setOpenManageAccount(false);
      toast({
        title: "Permissions updated",
        description: "Your permissions have been updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    setLoading(false);
  };

  async function handleAddNewUser() {
    fetchGetUidFromEmail([newUser, ...allowedUsers]).then((response) => {
      if (response.status === "error") {
        toast({
          title: "Error",
          description: response.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      const newUserUid = response.uids.splice(0, 1)[0];
      if ([...masterUids, ...response.uids].includes(newUserUid)) {
        return toast({
          title: "Error",
          description: "This email already have access to your campaign",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setAllowedUsers(prev => [...prev, newUser]);
      setNewUser('');
    })
  };

  const handleKeyPressEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setAllowedRegisterEmails(prev => [...prev, newEmail]);
      setNewEmail('');
    }
  };

  const handleRemoveUser = (email: string) => {
    setAllowedUsers(prev => prev.filter(item => item !== email));
  };

  const handleRemoveEmail = (email: string) => {
    setAllowedRegisterEmails(prev => prev.filter(item => item !== email));
  };

  return (
    <Modal size="xl" isOpen={openManageAccount} onClose={() => setOpenManageAccount(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="20px">Permissions</Text>
          <Divider />
          <Text fontWeight={600} mt="2%" fontSize="15px">Share Access to Your Company's Campaigns</Text>
          <Text fontSize="12px" color="gray.600">Users must have an account already registered at CalledOn.ai</Text>
          <Select value={companySelected} onChange={(e) => {setCompanySelected(e.target.value)}}  placeholder="First, select a company" mt="2%" mb="2%">
            {allCampaigns && Object.keys(allCampaigns)
              .filter((campaignId) => allCampaigns[campaignId].createdBy === auth.currentUser.uid)
              .map((campaignId) => allCampaigns[campaignId].dealName)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((dealName, idx) => (
                <option key={idx} value={dealName}>{dealName}</option>
              ))}
          </Select>
          {companySelected && (
          <Box>
            <Text fontWeight={600} mt="2%" fontSize="15px">Now add the emails you want to share your campaigns with</Text>
            <Text fontSize="12px" color="gray.600">You may add as many emails as you want</Text>
            <Flex>
              <Input
                borderRadius="20px"
                value={newUser}
                onChange={(e) => setNewUser(e.target.value)}
                mt="2%"
                placeholder="Type an email and press Enter"
                onKeyPress={(e) => {if (e.key === 'Enter'){handleAddNewUser()}}}
              />
              <IconButton borderRadius="20px" variant="outline" marginLeft="2%" marginTop="auto" aria-label="Add email" icon={<AddIcon />} onClick={() => {handleAddNewUser()}} />
            </Flex>
            <Wrap mt="4%" spacingX="35px">
            {allowedUsers.map((email,idx) => (
              <EmailBadge key={idx} email={email} onRemove={() => handleRemoveUser(email)} />
            ))}
            </Wrap>
          </Box>)}
          {masterUids.includes(auth.currentUser.uid) && (
            <Box marginTop="5%">
                <Text fontSize="20px">Master Access</Text>
                <Divider />
                <Text fontWeight={600} mt="2%" fontSize="15px">Allowed Emails</Text>
                <Text fontSize="12px" color="gray.600">Manage emails that are allowed to register at CalledOn.ai</Text>
                <Input
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    mt="2%"
                    placeholder="Type an email and press Enter"
                    onKeyPress={handleKeyPressEmail}
                />
                <Wrap mt="2%" spacingX="35px">
                    {allowedRegisterEmails.map((email,idx) => (
                        <EmailBadge key={idx} email={email} onRemove={() => handleRemoveEmail(email)} />
                    ))}
                </Wrap>
            </Box>
            )}
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} onClick={changePermissions} variant="outline">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
