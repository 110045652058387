import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Spacer,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useCampaignsState } from "../../../hooks/useCampaignsState";
import fetchCallCustomer from "../../../helpers/fetchCallCustomer";
import fetchTerminateCall from "../../../helpers/fetchTerminateCall";
import { pickByMode } from '../../../utils/theme';

const CallActions = ({
  currentCall,
  deleteCustomer,
  realTimeConv,
  selectedCustomer,
  setCurrentCall,
}) => {
  const { campaignId } = useParams<{
    campaignId: string;
  }>();
  const campaignData = useCampaignsState()?.campaigns?.[campaignId!];
  const toast = useToast();
  const [loadingCall, setLoadingCall] = useState<boolean>(false);
  const { colorMode } = useColorMode();

  function callCustomer() {
    if (selectedCustomer) {
      setLoadingCall(true);
      fetchCallCustomer(campaignId!, selectedCustomer, toast).then(
        (callKey) => {
          setLoadingCall(false);
          if (callKey) {
            setCurrentCall(callKey);
          }
        }
      );
    }
  }

  return (
    <Box>
      <Text fontSize="20px" fontWeight={700} color="gray.400" marginBottom="1%">
        Actions
      </Text>
      {currentCall.length === 0 ? (
        <Center>
          <Button
            isLoading={loadingCall}
            onClick={() => {
              callCustomer();
            }}
            colorScheme="green"
            leftIcon={<PhoneIcon />}
            variant="outline"
          >
            Call Customer
          </Button>
          <Button
            onClick={() => {
              deleteCustomer();
            }}
            marginLeft="5%"
            colorScheme="red"
            variant="outline"
            leftIcon={<DeleteIcon />}
          >
            Delete Customer
          </Button>
        </Center>
      ) : (
        <Center>
          <Box w="80%">
            <Box
              bg={pickByMode(colorMode, "white", "gray.900")}
              boxShadow="lg"
              marginTop="2%"
              h="50vh"
              w="100%"
              border={`1.2px solid ${pickByMode(colorMode, "#dfebf6", "#2e2e2e")}`}
              borderRadius="15px"
            >
              <Flex h="7vh" alignItems="center" px={4}>
                <Text
                  fontSize="lg"
                  fontWeight={500}
                >
                  Real Time Transcription
                </Text>
                <Spacer />
                {realTimeConv.streaming ? (
                  <Badge
                    colorScheme="green"
                    variant="outline"
                    padding={2}
                    borderRadius="20px"
                    margin="auto"
                    fontWeight={500}
                  >
                    Connected
                  </Badge>
                ) : (
                  <Badge
                    colorScheme="blue"
                    variant="outline"
                    borderRadius="20px"
                    margin="auto"
                    padding=".7%"
                    fontWeight={500}
                    marginRight="2%"
                  >
                    Connecting...
                  </Badge>
                )}
              </Flex>
              <Divider />
              {realTimeConv.dialogue && realTimeConv.dialogue.length > 0 && (
                <Box overflowY="auto" h="43vh" padding="2%">
                  {realTimeConv.dialogue.map((message, index) => {
                    if (message.role !== "system") {
                      return (
                        <Flex
                          marginTop="2%"
                          key={index}
                          justifyContent={
                            message.role === "user" ? "flex-end" : "flex-start"
                          }
                        >
                          {message.role === "assistant" && (
                            <Avatar
                              name={
                                message.role === "user"
                                  ? selectedCustomer.name
                                  : campaignData?.repName
                              }
                              size="sm"
                              marginRight={message.role === "user" ? "0" : "2"}
                              marginLeft={message.role === "user" ? "2" : "0"}
                            />
                          )}
                          <Box
                            bg={
                              message.role === "assistant" ? "blue.100" : "gray.100"
                            }
                            boxShadow="lg"
                            borderRadius="10px"
                            padding="2%"
                            maxWidth="70%"
                          >
                            <Text fontSize={13} fontWeight={600} color="gray.600" >
                              {message.role === "user"
                                ? selectedCustomer.name
                                : campaignData?.repName}
                            </Text>
                            <Text color="gray.600" fontSize={12}>
                              {message.content}
                            </Text>
                          </Box>
                          {message.role === "user" && (
                            <Avatar
                              name={selectedCustomer.name}
                              size="sm"
                              marginLeft={2}
                            />
                          )}
                        </Flex>
                      );
                    }
                    return null;
                  })}
                </Box>
              )}
            </Box>
            <Center marginTop="2%">
              <Button
                onClick={() => {
                  fetchTerminateCall(currentCall, campaignId!);
                }}
                variant="outline"
                colorScheme="red"
                leftIcon={<PhoneIcon />}
              >
                End Call
              </Button>
            </Center>
          </Box>
        </Center>
      )}
    </Box>
  );
};

export default CallActions;
