import {
  WrapItem,
  Divider,
  Flex,
  Badge,
  Box,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { CampaignCardInterface } from "../utils/interfaces";
import { Link } from "react-router-dom";
import { pickByMode } from "../utils/theme";

export default function CampaignCard({
  campaign,
  setHoveredProject,
  hoveredProject,
}: CampaignCardInterface) {
  const { colorMode } = useColorMode();
  return (
    <Link to={`${campaign.id}/dashboard`}>
      <WrapItem
        key={campaign.id}
        onMouseEnter={() => setHoveredProject(campaign.id)}
        onMouseLeave={() => setHoveredProject("")}
      >
        <Box
          opacity={hoveredProject === campaign.id ? "0.85" : "1"}
          transform={hoveredProject === campaign.id ? "scale(1.05)" : "none"}
          cursor="pointer"
          borderRadius="15px"
          boxShadow="lg"
          w="300px"
          h="230px"
          transition="all 0.3s ease"
          bg={pickByMode(colorMode, "#fff", "#000")}
        >
          <Image
            bg="gray.200"
            borderTopRadius="15px"
            src={campaign.imageUrl || "logo.png"}
            alt={campaign.campaignName}
          />
          <Divider />
          <Box padding="5%">
            <Flex align="center">
              <Text fontSize="lg" fontWeight="semibold" isTruncated>
                {campaign.campaignName}
              </Text>
              <Badge ml="2" colorScheme="green" borderRadius="full">
                {campaign.repName}
              </Badge>
            </Flex>
          </Box>
        </Box>
      </WrapItem>
    </Link>
  );
}
