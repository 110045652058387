import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestoreDb } from '../../firebase';
import dayjs, { Dayjs } from 'dayjs';

export interface IStats {
  totalCalls?: number;
  successCalls?: number;
  averageCallDuration?: number;
  appointments?: number;
}

export const fetchStatsByPeriod = async (
  campaignId: string,
  period: 'daily' | 'monthly' | 'yearly',
): Promise<Array<{ name: string, totalCalls: number, successCalls: number, appointments: number }>> => {
  const today = dayjs();
  let periodCount;
  let startDate;

  switch (period) {
    case 'daily':
      periodCount = 10; // Last 10 days
      startDate = today.subtract(periodCount - 1, 'days').startOf('day');
      break;
    case 'monthly':
      periodCount = 5; // Last 5 months
      startDate = today.subtract(periodCount - 1, 'months').startOf('month');
      break;
    case 'yearly':
      periodCount = 3; // Last 3 years
      startDate = today.subtract(periodCount - 1, 'years').startOf('year');
      break;
  }

  const stats = Array.from({ length: periodCount }, (_, i) => {
    const date = startDate.add(i, period === 'daily' ? 'days' : period === 'monthly' ? 'months' : 'years');
    return {
      name: period === 'daily' ? date.format("DD MMM") :
      period === 'monthly' ? date.format("MMM YYYY") :
      date.format("YYYY"),
      totalCalls: 0,
      successCalls: 0,
      appointments: 0
    };
  });

  const dailyStatsRef = collection(
    firestoreDb,
    "campaigns",
    campaignId,
    "dailyAnalytics"
  );

  const q = query(
    dailyStatsRef,
    where("timestamp", ">=", startDate.unix()),
    where("timestamp", "<=", today.unix())
  );

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const dataDate = dayjs.unix(data.timestamp);
    const index = dataDate.diff(startDate, period === 'daily' ? 'days' : period === 'monthly' ? 'months' : 'years');
    if (index >= 0 && index < periodCount) {
      stats[index].totalCalls += data.totalCalls || 0;
      stats[index].successCalls += data.successCalls || 0;
      stats[index].appointments += data.appointments || 0;
    }
  });

  return stats;
}


export const fetchStats = async (
  campaignId: string,
  startDate?: Dayjs,
  endDate?: Dayjs
): Promise<IStats> => {
  let statsDocRef;
  if (startDate && endDate) {
    const dailyStatsRef = collection(
      firestoreDb,
      "campaigns",
      campaignId,
      "dailyAnalytics"
    );
    const q = query(
      dailyStatsRef,
      where("timestamp", ">=", startDate.unix()),
      where("timestamp", "<=", endDate.unix())
    );
    const querySnapshot = await getDocs(q);
    const aggregatedStats: IStats = {
      totalCalls: 0,
      successCalls: 0,
      averageCallDuration: 0,
      appointments: 0,
    };
    let totalDuration = 0;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      aggregatedStats.totalCalls += data.totalCalls || 0;
      aggregatedStats.successCalls += data.successCalls || 0;
      aggregatedStats.appointments += data.appointments || 0;
      totalDuration += (data.averageCallDuration || 0) * (data.totalCalls || 0);
    });
    aggregatedStats.averageCallDuration =
      aggregatedStats?.totalCalls > 0
        ? Math.round(totalDuration / aggregatedStats?.totalCalls)
        : 0;
    return aggregatedStats;
  } else {
    statsDocRef = doc(
      firestoreDb,
      "campaigns",
      campaignId,
      "analytics",
      "totalStats"
    );
  }
  const statsSnapshot = await getDoc(statsDocRef);
  return (statsSnapshot.exists() ? statsSnapshot.data() : {}) as IStats;
};
