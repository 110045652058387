import { fetcher } from '../utils/api/fetcher';

export default async function fetchCheckCompanyName(name:string) {
    const body = {
        name
    }
    const response = await fetcher('/check_company_name', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
