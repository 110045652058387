import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { fetcher } from "../../utils/api/fetcher";
import { queryClient } from "../../utils/queryClient";

interface RemoveAgentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId?: string;
  userToRemove: string | null;
  setUserToRemove: (email: string | null) => void;
}

const RemoveAgentDialog: React.FC<RemoveAgentDialogProps> = ({
  isOpen,
  onClose,
  campaignId,
  userToRemove,
  setUserToRemove,
}) => {
  const toast = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const removeAgentMutation = useMutation({
    mutationFn: async (email: string) => {
      const response = await fetcher(
        `/agents/remove?campaign_id=${campaignId}&email=${email}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove user");
      }

      return email;
    },
    onSuccess: (email) => {
      toast({
        title: "User removed.",
        description: "The user has been successfully removed.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setUserToRemove(null);
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["agents", campaignId],
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to remove user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const confirmRemoveUser = () => {
    if (userToRemove) {
      removeAgentMutation.mutate(userToRemove);
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={!removeAgentMutation.isPending}
      closeOnOverlayClick={!removeAgentMutation.isPending}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm Removal
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to remove this agent?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={confirmRemoveUser}
              isLoading={removeAgentMutation.isPending}
              isDisabled={removeAgentMutation.isPending}
            >
              Remove
            </Button>
            <Button
              onClick={onClose}
              ml={3}
              isDisabled={removeAgentMutation.isPending}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default RemoveAgentDialog;
