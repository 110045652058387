import React, { useState, FC, useEffect } from 'react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { Center, Button, useToast, Box, Image, Input, Stack, Tab, TabList, Tabs, TabPanels, TabPanel, Flex, useColorMode } from '@chakra-ui/react';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendEmailVerification, AuthError, User } from 'firebase/auth';
import { auth } from '../firebase';
import { BsGoogle } from 'react-icons/bs';
import fetchCheckEmail from '../helpers/fetchCheckEmail';
import { pickByMode } from '../utils/theme';

interface AuthPageState {
  email: string;
  password: string;
  allowedEmails: string[];
  plan?: any;
  creditCard: {
    number: string;
    expiry: string;
    cvv: string;
  };
}

const AuthenticationPage: FC = () => {
  const toast = useToast();
  const {colorMode} = useColorMode()
  const [activeTab, setActiveTab] = useState(0);
  const [state, setState] = useState<AuthPageState>({
    email: '',
    password: '',
    allowedEmails: [],
    plan: null,
    creditCard: {
      number: '',
      expiry: '',
      cvv: ''
    }
  });

  useEffect(() => {
    // Clean the pathname if it's not the root and user logs out
    if (window.location.pathname !== "/") {
      window.location.pathname = "/";
    }
  }, [])

  const handleError = (error: AuthError) => {
    toast({
      title: 'Error',
      description: error.message,
      status: 'error',
      duration: 5000,
      isClosable: true
    });
  };

  const googleSignInButton = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const userEmail = result.user.email;
      const emailResponse = await fetchCheckEmail(userEmail);
      if (emailResponse.status === 'success') {
        window.location.reload();
      } else {
        await signOut(auth);
        toast({
          title: "Unauthorized Access",
          description: "You are not authorized to access this application.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      handleError(error as AuthError);
    }
  };

  const signInWithEmail = async () => {
    try {
      const emailResponse = await fetchCheckEmail(state.email);
      if (emailResponse.status !== 'success') {
        toast({
          title: "Unauthorized Access",
          description: "You are not authorized to access this application.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
        return;
      }
      const userCredential = await signInWithEmailAndPassword(auth, state.email, state.password);
      if (!userCredential.user.emailVerified) {
        toast({
          title: "Email Verification Required",
          description: "Please check your email to verify your account.",
          status: "warning",
          duration: 5000,
          isClosable: true
        });
        await signOut(auth);
      } else {
        window.location.reload();
      }
    } catch (error) {
      handleError(error as AuthError);
    }
  };

  const sendVerificationEmail = async (user: User) => {
    try {
      await sendEmailVerification(user);
    } catch (error) {
      handleError(error as AuthError);
    }
  };

  const signUpWithEmail = async () => {
    const emailResponse = await fetchCheckEmail(state.email);
    if (emailResponse.status !== 'success') {
      toast({
        title: "Unauthorized Access",
        description: "You are not authorized to access this application.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, state.email, state.password);
      await sendVerificationEmail(userCredential.user);
      toast({
        title: "Account Created",
        description: "A verification email has been sent. Please verify your email.",
        status: "success",
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      handleError(error as AuthError);
    }
  };

  return (
    <Center h="100vh">
      <Flex flexDirection="column" alignItems="center" >
        <Image  src={pickByMode(colorMode, "logo.png", "logo-dark.png")} my="5" w="72" />
        <Box p="10" bg="Background">
        <Tabs  w="md"  marginBottom="5%" isFitted variant="enclosed" onChange={setActiveTab} index={activeTab}>
          <TabList mb="1em">
            <Tab>Sign In</Tab>
            <Tab>Sign Up</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={4}>
                <Input
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) => setState(prev => ({ ...prev, email: e.target.value }))}
                />
                <Input
                  placeholder="Password"
                  type="password"
                  value={state.password}
                  onChange={(e) => setState(prev => ({ ...prev, password: e.target.value }))}
                />
                <Button onClick={signInWithEmail} leftIcon={<EmailIcon />}>Sign In</Button>
                <Button onClick={googleSignInButton} leftIcon={<BsGoogle />}>Sign In with Google</Button>
              </Stack>
            </TabPanel>
            <TabPanel >
              <Stack  spacing={4}>
                <Input
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) => setState(prev => ({ ...prev, email: e.target.value }))}
                />
                <Input
                  placeholder="Password"
                  type="password"
                  value={state.password}
                  onChange={(e) => setState(prev => ({ ...prev, password: e.target.value }))}
                />
                <Button onClick={signUpWithEmail} leftIcon={<LockIcon />}>Sign Up</Button>
                <Button onClick={googleSignInButton} leftIcon={<BsGoogle />}>Sign Up with Google</Button>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
        </Box>
      </Flex>
    </Center>
  );
};

export default AuthenticationPage;
