import { pickByMode } from "../../utils/theme";
import {
  CallIcon,
  PositiveRateIcon,
  SuccessRateIcon,
} from "../../assets/icons";
import { Grid, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { Box, Center, Flex } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MdOutlineTimer } from "react-icons/md";
import { IStats, fetchStats } from "./utils";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const StatCard = ({ title, value, icon, bgColor, isLoading }) => {
  const { colorMode } = useColorMode();
  return (
    <Skeleton borderRadius="15px" isLoaded={!isLoading} >
      <Box
        w="100%"
        bg="Background"
        border={colorMode === "light" ? "1px solid #EEEFFF" : "none"}
        p="17px"
        borderRadius="15px"
        h="141px"
      >
        <Center mb="16px" h="48px" w="48px" borderRadius="12px" bg={bgColor}>
          {icon}
        </Center>
        <Text fontSize="18px" fontWeight="500" lineHeight="27px">
          {value}
        </Text>
        <Text fontSize="12px" color="#838383" mt="4px" lineHeight="18px">
          {title}
        </Text>
      </Box>
    </Skeleton>
  );
};

const Stats = () => {
  const { campaignId } = useParams();
  const { colorMode } = useColorMode();

  const [filter, setFilter] = useState("all");
  const {
    data: stats,
    isLoading,
    error,
  } = useQuery<IStats>({
    queryKey: ["stats", campaignId, filter],
    queryFn: () => {
      if (!campaignId) return Promise.resolve({});

      switch (filter) {
        case "today":
          const today = dayjs();
          return fetchStats(campaignId, today.startOf('day'), today);
        case "yesterday":
          const yesterday = dayjs().subtract(1, "day");
          return fetchStats(campaignId, yesterday.startOf('day'), yesterday);
        case "last7days":
          const last7Days = dayjs().subtract(6, "days").startOf('day');
          return fetchStats(
            campaignId,
            last7Days,
            dayjs()
          );
        case "monthtodate":
          const monthToDate = dayjs().startOf("month").startOf('day');
          return fetchStats(
            campaignId,
            monthToDate,
            dayjs()
          );
        default:
          return fetchStats(campaignId);
      }
    },
  });

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}min${minutes > 1 ? "s" : ""} ${remainingSeconds}s`;
  };

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          size="md"
          width="170px"
          marginBottom="4"
        >
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last7days">Last 7 days</option>
          <option value="monthtodate">Month to date</option>
        </Select>
      </Flex>
      <Grid
        templateColumns={{ base: "1fr", sm: "1fr 1fr", lg: "repeat(4, 1fr)" }}
        gap={6}
      >
        <StatCard
          icon={<CallIcon fill={"#646cfb"} />}
          title="Outbound Calls"
          value={stats?.totalCalls || 0}
          bgColor={pickByMode(colorMode, "#EEEFFF", "#272A2F")}
          isLoading={isLoading}
        />
        <StatCard
          icon={<SuccessRateIcon />}
          title="Successfull Calls"
          value={stats?.successCalls || 0}
          bgColor={pickByMode(colorMode, "rgba(35, 204, 72, .18)", "#272A2F")}
          isLoading={isLoading}
        />
        <StatCard
          icon={<MdOutlineTimer size={26} color="#f9724d" />}
          title="Average Call Duration"
          value={
            stats?.averageCallDuration
              ? formatDuration(stats?.averageCallDuration)
              : "0s"
          }
          bgColor={pickByMode(colorMode, "#FFF0EC", "#272A2F")}
          isLoading={isLoading}
        />
        <StatCard
          icon={<PositiveRateIcon />}
          title="Appointments"
          value={stats?.appointments || 0}
          bgColor={pickByMode(colorMode, "#FFDC9B", "#272A2F")}
          isLoading={isLoading}
        />
      </Grid>
    </Box>
  );
};

export default Stats;
