import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import { pickByMode } from "../../../utils/theme";
import CallModalInfo from "./CallInfoModal";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../../../firebase";
import { useParams } from "react-router-dom";

const CustomerCalls = ({
  selectedCustomer,
  currentCall,
  setCurrentCall,
  setRealTimeConv,
}) => {
  const { colorMode } = useColorMode();
  const { campaignId } = useParams();
  const [openCallInfo, setOpenCallInfo] = useState<string>("");
  const [rowHover, setRowHover] = useState<number>(-1);
  const [allCustomerCalls, setAllCustomerCalls] = useState<any>();

  useEffect(() => {
    //Only get calls where toNumber === selectedCustomer.phone_number
    if (selectedCustomer) {
      const callsRef = collection(
        firestoreDb,
        "campaigns",
        campaignId!,
        "calls"
      );
      const q = query(
        callsRef,
        where("toNumber", "==", selectedCustomer.phone_number),
        orderBy("startCall", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const calls: any = {};
        snapshot.forEach((doc) => {
          calls[doc.id] = doc.data();
        });
        setAllCustomerCalls(calls);
      });
      return () => unsubscribe();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (currentCall) {
      setRealTimeConv(allCustomerCalls[currentCall]);
      if (allCustomerCalls[currentCall].endCall) {
        setCurrentCall("");
      }
    }
  }, [allCustomerCalls, currentCall]);

  return (
    <Box>
      <Text fontSize="20px" fontWeight={700} color="gray.400" marginBottom="1%">
        Previous Calls
      </Text>
      <Box
        padding="3%"
        w="100%"
        boxShadow="lg"
        border={`1px solid  ${pickByMode(colorMode, "#e8e8e8", "#2e2e2e")}`}
        borderRadius="10px"
        h="350px"
        overflowY="scroll"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Answer</Th>
              <Th>Date</Th>
              <Th>Time</Th>
              <Th>Length</Th>
              <Th>Goal Accomplished</Th>
            </Tr>
          </Thead>
          <Tbody h="200px">
            {allCustomerCalls &&
              Object.keys(allCustomerCalls)
                .sort(
                  (a, b) =>
                    allCustomerCalls[b].startCall -
                    allCustomerCalls[a].startCall
                )
                .map((callIdx, idx) => (
                  <Tr
                    onClick={() => {
                      setOpenCallInfo(callIdx);
                    }}
                    bg={
                      rowHover === idx
                        ? pickByMode(colorMode, "gray.100", "gray.900")
                        : ""
                    }
                    cursor="pointer"
                    onMouseEnter={() => {
                      setRowHover(idx);
                    }}
                    onMouseLeave={() => {
                      setRowHover(-1);
                    }}
                  >
                    <Td>
                      <PhoneIcon
                        color={
                          allCustomerCalls[callIdx].dialogue
                            ? "green.500"
                            : "red.500"
                        }
                      />
                    </Td>
                    <Td>
                      {new Date(
                        parseInt(allCustomerCalls[callIdx].startCall) * 1000
                      ).toLocaleDateString()}
                    </Td>
                    <Td>
                      {new Date(
                        parseInt(allCustomerCalls[callIdx].startCall) * 1000
                      ).toLocaleTimeString()}
                    </Td>
                    <Td>
                      {!allCustomerCalls[callIdx].dialogue
                        ? ""
                        : allCustomerCalls[callIdx].endCall -
                            allCustomerCalls[callIdx].startCall <
                          60
                        ? `${
                            (allCustomerCalls[callIdx].endCall -
                            allCustomerCalls[callIdx].startCall).toFixed(0)
                          } seconds`
                        : `${Math.floor(
                            (allCustomerCalls[callIdx].endCall -
                              allCustomerCalls[callIdx].startCall) /
                              60
                          )} minute(s)`}
                    </Td>
                    <Td>
                      {allCustomerCalls[callIdx].evaluation
                        ? allCustomerCalls[callIdx].evaluation
                            .objectiveAccomplished
                        : "Under Analysis"}
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        <CallModalInfo
          allCustomerCalls={allCustomerCalls}
          openCallInfo={openCallInfo}
          setOpenCallInfo={setOpenCallInfo}
          selectedCustomer={selectedCustomer}
        />
      </Box>
    </Box>
  );
};

export default CustomerCalls;
