import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
} from "@chakra-ui/react";
import { DatePicker } from "../../components/DatePicker";
import CSVUploader from "../../components/csvUploader";
import {
  PhoneIcon,
  AtSignIcon,
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  StarIcon,
  DownloadIcon,
  ChevronDownIcon,
  Icon,
} from "@chakra-ui/icons";
import { useEffect, useState, useCallback } from "react";

import { firestoreDb } from "../../firebase";
import { CampaignDataInterface, CustomerInterface } from "../../utils/interfaces";

import { BsPlay } from "react-icons/bs";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  getDocs,
  limit,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";
import { FaCircle } from "react-icons/fa";
import { capitalize } from "../../utils/others";
import { useParams } from "react-router-dom";
import { useCampaignsState } from "../../hooks/useCampaignsState";
import Content from "../../components/Content";
import { pickByMode } from '../../utils/theme';

import CustomerDetails from './CustomerDetails';

const pageSize = 20;

export default function CustomersPage() {
  const { campaignId } = useParams() as { campaignId: string };
  const campaignData = useCampaignsState()?.campaigns?.[campaignId] as CampaignDataInterface;
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [allCustomers, setAllCustomers] = useState<CustomerInterface[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [validCustomers, setValidCustomers] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [rowHover, setRowHover] = useState<number>(-1);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerInterface>();
  
  
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState<null | Date>(null);

  const downloadCsv = useCallback((dataToExport: any[], filename: string) => {
    const csvRows = [
      [
        "Name",
        "Phone",
        "Email",
        "Product",
        "Address",
        "Audience",
        "Attempts",
        "Last Attempt",
      ],
      ...dataToExport.map((customer) => [
        customer.name || "",
        customer.phone_number || "",
        customer.email || "",
        customer.product || "",
        customer.address || "",
        customer.audience || "",
        customer.calls
          ? `${customer.calls[customer.calls.length - 1].answered ? "1" : 0}/${
              customer.calls.length
            }`
          : "",
        customer.calls
          ? new Date(
              parseInt(customer.calls[customer.calls.length - 1].createdAt) *
                1000
            ).toLocaleDateString()
          : "",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvRows], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  useEffect(() => {
    const fetchFilteredCustomers = async () => {
      if (selectedDate) {
        const startOfDay =
          new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
        const endOfDay =
          new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
        const callsRef = collection(
          firestoreDb,
          `campaigns/${campaignId}/calls`
        );
        const q = query(
          callsRef,
          where("startCall", ">=", startOfDay),
          where("startCall", "<=", endOfDay)
        );
        const callDocs = await getDocs(q);
        const customerIdsWithCalls = new Set(
          callDocs.docs.map((doc) => doc.data().customerId)
        );
        const filteredCustomers = allCustomers.filter((customer) =>
          customerIdsWithCalls.has(customer.id)
        );
        setValidCustomers(filteredCustomers);
        setTotalPages(Math.ceil(filteredCustomers.length / pageSize));
      } else {
        setValidCustomers(allCustomers);
        setTotalPages(Math.ceil(allCustomers.length / pageSize));
      }
    };
    fetchFilteredCustomers().catch(console.error);
  }, [selectedDate, allCustomers, campaignId]);

  const handleExportAll = useCallback(() => {
    downloadCsv(
      validCustomers,
      `${campaignData?.campaignName}-customers-all.csv`
    );
  }, [validCustomers, campaignData?.campaignName, downloadCsv]);

  const handleExportUnanswered = useCallback(() => {
    const unansweredCustomers = validCustomers.filter(
      (customer) =>
        !customer.calls ||
        customer.calls.length === 0 ||
        !customer.calls[customer.calls.length - 1]?.answered
    );
    if (unansweredCustomers.length === 0) {
      toast({
        title: "No unanswered customers",
        description: "No unanswered customers found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    downloadCsv(
      unansweredCustomers,
      `${campaignData?.campaignName}-customers-unanswered.csv`
    );
  }, [validCustomers, campaignData?.campaignName, downloadCsv]);

  const handleExportAnswered = useCallback(() => {
    const answeredCustomers = validCustomers.filter(
      (customer) =>
        customer.calls && customer.calls[customer.calls.length - 1]?.answered
    );
    if (answeredCustomers.length === 0) {
      toast({
        title: "No answered customers",
        description: "No answered customers found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    downloadCsv(
      answeredCustomers,
      `${campaignData?.campaignName}-customers-answered.csv`
    );
  }, [validCustomers, campaignData?.campaignName, downloadCsv]);

  useEffect(() => {
    const customersRef = collection(
      firestoreDb,
      "campaigns",
      campaignId,
      "customers"
    );
    const q = query(
      customersRef,
      orderBy("name"),
      startAfter(currentPage * pageSize),
      startAt(capitalize(searchCustomer)),
      endAt(capitalize(searchCustomer) + "\uf8ff"),
      limit(pageSize)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const customers: any = [];
        snapshot.forEach((doc) => {
          customers.push({ id: doc.id, ...doc.data() });
        });
        setAllCustomers(customers);
        setTotalPages(Math.ceil(customers.length / pageSize));
      },
      (error) => {
        console.error("Error fetching customers:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const startOfDay =
        new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
      const endOfDay =
        new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
      const filteredCustomers = allCustomers.filter(
        (customer) =>
          customer?.calls &&
          customer?.calls.some(
            (call) => call.createdAt >= startOfDay && call.createdAt <= endOfDay
          )
      );
      setValidCustomers(filteredCustomers);
      setTotalPages(Math.ceil(filteredCustomers.length / pageSize));
    } else {
      setValidCustomers(allCustomers);
      setTotalPages(Math.ceil(allCustomers.length / pageSize));
    }
  }, [selectedDate, allCustomers]);

  

  function formatPhoneNumber(number: number) {
    let numberString = number.toString();
    if (numberString) {
      numberString = numberString.toString();
      const intCode = numberString.substring(0, 2);
      const areaCode = numberString.substring(2, 5);
      const first3Digits = numberString.substring(5, 8);
      const last4Digits = numberString.substring(8);
      return `${intCode} (${areaCode}) ${first3Digits}-${last4Digits}`;
    }
  }

  

  useEffect(() => {
    if (allCustomers) {
      const validCustomers = allCustomers
        .map((customer, index) => ({ ...customer, index }))
        .filter((customer) => {
          const customerValues = Object.values(customer).map((value) =>
            typeof value === "string" ? value.toLowerCase() : ""
          );
          return customerValues.some((value) =>
            value.includes(searchCustomer.toLowerCase())
          );
        });
      setValidCustomers(validCustomers);
      setTotalPages(Math.ceil(validCustomers.length / pageSize));
    }
  }, [searchCustomer, allCustomers]);

  
  
  function updateCampaignStatus() {
    if (allCustomers.length === 0) {
      toast({
        title: "No customers",
        description:
          "You need to upload customers before activating the campaign",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (campaignData) {
      const campaignRef = doc(firestoreDb, "campaigns", campaignId);

      updateDoc(campaignRef, {
        active: !campaignData.active,
      })
        .then(() => {
          toast({
            title: campaignData.active
              ? "Campaign stopped"
              : "Campaign started",
            description: campaignData.active
              ? "Your campaign has been stopped and calls will pause"
              : "Your campaign has been started and calls will start soon",
            status: campaignData.active ? "warning" : "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error("Error updating campaign status:", error);
          toast({
            title: "Error",
            description: "Failed to update campaign status",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }

  

  function pickLabelCircle(calls) {
    if (calls) {
      const lastCall = calls[calls.length - 1];
      if (lastCall.answered) {
        return (
          "Call successful at " +
          new Date(parseInt(lastCall.createdAt) * 1000).toLocaleTimeString() +
          " on " +
          new Date(parseInt(lastCall.createdAt) * 1000).toLocaleDateString()
        );
      } else {
        return (
          `Attempt #${calls.length} of trying to reach customer failed at ` +
          new Date(parseInt(lastCall.createdAt) * 1000).toLocaleTimeString() +
          " on " +
          new Date(parseInt(lastCall.createdAt) * 1000).toLocaleDateString()
        );
      }
    }
    return "No calls yet";
  }

  function pickCircleColor(calls) {
    if (calls) {
      const lastCall = calls[calls.length - 1];
      if (lastCall.answered) {
        return "green.500";
      } else {
        return `red.${calls.length * 200}`;
      }
    }
    return "gray.500";
  }

  const { colorMode } = useColorMode();


  return (
    <Content>
      <Box
        padding="5"
        bg="Background"
        border={colorMode === "light" ? "1px solid #EEEFFF" : "none"}
        borderRadius="10px"
      >
        <Flex alignItems="center" mb="10px">
          <Flex>
            <Tooltip
              label={
                campaignData.active
                  ? "Campaign is active"
                  : "Campaign is not active"
              }
            >
              <IconButton
                colorScheme={campaignData.active ? "green" : "orange"}
                variant="outline"
                aria-label="Play Campaign"
                icon={<BsPlay fontSize="25px" />}
                onClick={() => {
                  updateCampaignStatus();
                }}
                size="md"
                borderRadius="30px"
                marginTop="auto"
                marginBottom="auto"
                marginLeft="3%"
              />
            </Tooltip>
          </Flex>
          <Spacer />
          <CSVUploader
            campaignData={campaignData}
            campaignId={campaignId}
            allCustomers={allCustomers}
          />
          {validCustomers.length > 0 && (
            <Menu size="md">
              <MenuButton
                as={Button}
                leftIcon={<DownloadIcon />}
                size="sm"
                colorScheme="green"
                ml={2}
              >
                Export Customers <ChevronDownIcon />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleExportAll()}>All</MenuItem>
                <MenuItem onClick={() => handleExportUnanswered()}>
                  Unanswered
                </MenuItem>
                <MenuItem onClick={() => handleExportAnswered()}>
                  Answered
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        {!selectedCustomer ? (
          <Box>
            <Flex mb="40px">
              <InputGroup
                marginTop="auto"
                marginBottom="auto"
                minW="200px"
                maxW="300px"
                mr="10px"
                size="md"
              >
                <InputLeftElement>
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  value={searchCustomer}
                  onChange={(e) => {
                    setSearchCustomer(e.target.value);
                  }}
                  borderRadius="20px"
                  placeholder="Search"
                />
              </InputGroup>
              <DatePicker
                onChange={setSelectedDate}
                placeholderText="Select Date"
                selected={selectedDate}
                isClearable
                maxDate={new Date()}
                inputProps={{
                  size: "md",
                  minW: "200px",
                }}
              />
            </Flex>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Phone</Th>
                  <Th>Email</Th>
                  <Th>Product</Th>
                  <Th>Address</Th>
                  <Th>Audience</Th>
                  <Th>Attempt</Th>
                </Tr>
              </Thead>
              <Tbody>
                {validCustomers
                  .slice(pageSize * (currentPage - 1), pageSize * currentPage)
                  .map((contact: any, idx: number) => (
                    <Tr
                      key={idx}
                      onClick={() => {
                        setSelectedCustomer(contact);
                      }}
                      cursor="pointer"
                      onMouseEnter={() => {
                        setRowHover(idx);
                      }}
                      onMouseLeave={() => {
                        setRowHover(-1);
                      }}
                      bg={rowHover === idx ? pickByMode(colorMode, "gray.100", "gray.800")   : ""}
                    >
                      <Td>
                        <Tooltip label={contact.name}>
                          <Text noOfLines={1}>{contact.name}</Text>
                        </Tooltip>
                      </Td>
                      <Td>
                        <Flex>
                          <PhoneIcon
                            color="gray.600"
                            marginTop="auto"
                            marginBottom="auto"
                            mr={2}
                          />
                          <Tooltip
                            label={formatPhoneNumber(contact.phone_number)}
                          >
                            <Text noOfLines={1}>
                              {formatPhoneNumber(contact.phone_number)}
                            </Text>
                          </Tooltip>
                        </Flex>
                      </Td>
                      <Td>
                        <Flex>
                          <AtSignIcon
                            color="gray.600"
                            marginTop="auto"
                            marginBottom="auto"
                            mr={2}
                          />
                          <Tooltip label={contact.email}>
                            <Text noOfLines={1}>{contact.email}</Text>
                          </Tooltip>
                        </Flex>
                      </Td>
                      <Td>
                        <Flex>
                          <StarIcon
                            color="gray.600"
                            marginTop="auto"
                            marginBottom="auto"
                            mr={2}
                          />
                          <Tooltip label={contact.product}>
                            <Text noOfLines={1}>{contact.product}</Text>
                          </Tooltip>
                        </Flex>
                      </Td>
                      <Td>
                        <Flex>
                          <Tooltip label={contact.address}>
                            <Text noOfLines={1}>{contact.address}</Text>
                          </Tooltip>
                        </Flex>
                      </Td>
                      <Td>
                        <Tooltip label={contact.audience}>
                          <Text noOfLines={1}>{contact.audience}</Text>
                        </Tooltip>
                      </Td>
                      <Td>
                        <Center>
                          <Tooltip label={pickLabelCircle(contact.calls)}>
                            <Box>
                              <Icon
                                as={FaCircle}
                                color={pickCircleColor(contact.calls)}
                              />
                            </Box>
                          </Tooltip>
                        </Center>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Flex justify="space-between" align="center" mt={4}>
              <Button
                onClick={() => {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
                }}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
                variant="outline"
              >
                Previous
              </Button>
              <Text>
                Page {currentPage} of {totalPages}
              </Text>
              <Button
                variant="outline"
                onClick={() => {
                  setCurrentPage((prev) =>
                    prev < totalPages ? prev + 1 : prev
                  );
                }}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </Flex>
          </Box>
        ) : (
          <CustomerDetails
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
          />
        )}
      </Box>
    </Content>
  );
}
