import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import { useState } from "react";
import { ConfigPromptInterface } from "../../utils/interfaces";
import CommunicationGuidelines from "./CommunicationGuidelines";
import SampleScript from "./SampleScript";

const tabs = [
  {
    title: "Scripts Library",
    component: SampleScript,
  },
  {
    title: "Communication Guidelines",
    component: CommunicationGuidelines,
  },
];

export default function ConfigPrompt({
  openConfigPrompt,
  setOpenConfigPrompt,
}: ConfigPromptInterface) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const closeModal = () => {
    setOpenConfigPrompt(false);
    setSelectedTab(0);
  };

  const activeTab = tabs[selectedTab];
  return (
    <Modal size="3xl" isCentered isOpen={openConfigPrompt} onClose={closeModal} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent borderRadius="10px">
        <ModalHeader
          borderTopRadius="10px"
          bg="green.500"
          color="white"
          fontSize="lg"
          fontWeight="bold"
        >
          <Tabs
            onChange={setSelectedTab}
            index={selectedTab}
            size="md"
            variant="soft-rounded"
            w="95%"
            isFitted
            colorScheme='green'
          >
            <TabList>
              {tabs.map((tab, index) => {
                return (
                  <Tab key={index}>
                    <Text
                      color={
                        selectedTab === index ? "gray.700" : "whiteAlpha.900"
                      }
                    >
                      {tab.title}
                    </Text>
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>
        </ModalHeader>
        <ModalCloseButton />
        {activeTab.component && <activeTab.component />}
      </ModalContent>
    </Modal>
  );
}
