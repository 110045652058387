import Stats from "./Stats";
import LineGraph from "./LineGraph";
// import Table from './Table';
import Content from '../../components/Content';

const Dashboard = () => {
  return (
    <Content>
      <Stats />
      <LineGraph />
      {/* <Table /> */}
    </Content>
  );
};

export default Dashboard;
