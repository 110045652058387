import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { FaTrashAlt, FaEdit, FaEllipsisV, FaCheckCircle } from "react-icons/fa";
import dayjs from "dayjs";
import { pickByMode } from '../../../utils/theme';
import NewScriptModal from './NewScriptModal';
import { useState } from 'react';
import { MdCancel } from 'react-icons/md';

const ScriptListItem = ({ 
  scriptId, 
  onSelectScript, 
  onPressSetAsNativeScript, 
  onPressDeleteScript, 
  openScriptId, 
  watch, 
  onRenameScript,
  script
})  => {
  const { colorMode } = useColorMode();
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  
  const activeScriptId = watch("activeScriptId");

  return (
    <Box
      key={scriptId}
      borderRadius="md"
      padding="2"
      marginTop="2"
      cursor={"pointer"}
      _hover={{ bg: pickByMode(colorMode, "gray.100", "gray.600") }}
      bg={
        openScriptId === scriptId
          ? pickByMode(colorMode, "gray.100", "gray.600")
          : "transparent"
      }
      borderColor={
        activeScriptId === scriptId
          ? "green.400"
          : "transparent"
      }
      borderWidth={1}
      onClick={() => onSelectScript(scriptId)}
      alignItems="center"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {script?.name ? (
          <Text fontSize="sm">{script.name}</Text>
        ) : (
          <Text fontSize="sm">
            {dayjs(Number(scriptId)).format("D MMM, YYYY - hh:mm A")}
          </Text>
        )}
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisV />}
            variant="outline"
            size="sm"
            onClick={(event) => event.stopPropagation()}
          />
          <MenuList>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                onPressSetAsNativeScript(activeScriptId === scriptId ? null : scriptId);
              }}
              icon={activeScriptId === scriptId ? <MdCancel /> : <FaCheckCircle />}
            >
              {activeScriptId === scriptId ? "Set as Inactive" : "Set as Active"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRenameModalOpen(true);
              }}
              icon={<FaEdit />}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                onPressDeleteScript(scriptId);
              }}
              icon={<FaTrashAlt />}
              color="red"
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {activeScriptId === scriptId && (
        <Text fontSize="xs" color={"green.400"}>
          (Active)
        </Text>
      )}
      <NewScriptModal
        isOpen={isRenameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        onSubmitName={(newName) => {
          onRenameScript(scriptId, newName);
          setRenameModalOpen(false);
        }}
        scriptName={script?.name}
      />
    </Box>
  );
};

export default ScriptListItem;
