
import { create } from 'zustand'
import { CampaignDataInterface } from '../utils/interfaces';

interface CampaignsState {
  campaigns: { [key: string]: CampaignDataInterface } | null;
  setCampaigns: (data: any) => void
}


export const useCampaignsState = create<CampaignsState>((set) => ({
  campaigns: null,
  setCampaigns: (data) => set({ campaigns: data }),
}))