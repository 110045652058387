import {
  Box,
  Flex,
  useColorMode,
  useBreakpointValue,
  Skeleton,
} from "@chakra-ui/react";
import { fetchStatsByPeriod } from "./utils";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { pickByMode } from "../../utils/theme";
import { Select } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

const LineGraph = () => {
  const { campaignId } = useParams();
  const [period, setPeriod] = useState<"daily" | "monthly" | "yearly">(
    "daily"
  );
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["stats", "line-graph", period, campaignId],
    queryFn: () => fetchStatsByPeriod(campaignId!, period),
  });

  const { colorMode } = useColorMode();
  const boxHeight = useBreakpointValue({
    base: "300px",
    md: "400px",
    lg: "470px",
  });
  const boxPadding = useBreakpointValue({
    base: "16px",
    md: "25px",
    lg: "30px",
  });

  const formatYAxis = (tickItem) => {
    return tickItem > 1000 ? `${tickItem / 1000}k` : tickItem;
  };

  const responsiveContainerHeight = useBreakpointValue({
    base: 200,
    md: 300,
    lg: 350,
  });

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Skeleton borderRadius="15px" isLoaded={!isLoading}>
      <Box
        h={boxHeight}
        w="100%"
        bg="Background"
        borderRadius="15px"
        p={boxPadding}
        border={colorMode === "light" ? "1px solid #EEEFFF" : "none"}
      >
        <Flex justifyContent="flex-end" mb="4">
          <Select
            variant="filled"
            value={period}
            mb="4"
            w="150px"
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </Select>
        </Flex>
        <ResponsiveContainer width="100%" height={responsiveContainerHeight}>
          <LineChart width={500} height={246} data={data}>
            <CartesianGrid
              stroke={pickByMode(colorMode, "#E2E2E2", "#232323")}
              horizontal={false}
            />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxis} allowDecimals={false} />
            <Tooltip
              contentStyle={{
                backgroundColor: pickByMode(
                  colorMode,
                  "rgba(255,255,255, 0.42)",
                  "rgba(77,77,77, 0.42)"
                ),
                backdropFilter: "blur(15px)",
                border: pickByMode(colorMode, "1px solid #E2E2E2", "none"),
                borderRadius: "12px",
              }}
            />
            <Line
              type="monotone"
              dataKey="totalCalls"
              dot={false}
              strokeWidth={3}
              name="Total Calls"
              stroke="#646cfb"
            />
            <Line
              type="monotone"
              dataKey="successCalls"
              stroke="rgb(35, 204, 72)"
              dot={false}
              strokeWidth={3}
              name="Success Calls"
            />
            <Line
              type="monotone"
              dataKey="appointments"
              stroke="#f9bd4e"
              dot={false}
              strokeWidth={3}
              name="Appointments"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Skeleton>
  );
};

export default LineGraph;
