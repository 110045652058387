import { useMutation } from '@tanstack/react-query';
import { updateCampaignData } from '../utils/firebase';
import { queryClient } from '../utils/queryClient';
import { campaignsQueryKey } from './useCampaignsQuery';
import produce from 'immer';
import { useToast } from '@chakra-ui/react';

export const useSaveCampaignData = () => {
  const toast = useToast();
  const { isPending, mutateAsync: saveCampaignAsync } = useMutation({
    mutationFn: updateCampaignData,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: campaignsQueryKey });
      const previousData: any = queryClient.getQueryData(campaignsQueryKey);

      if (previousData) {
        queryClient.setQueryData(
          campaignsQueryKey,
          produce(previousData, (draft) => {
            draft[data.campaignId].promptTemplate = data.data.promptTemplate;
          })
        );
      }
      return { previousData };
    },
    onError: (err, variables, context) => {
      console.log("err:", err);
      toast({
        title: "Save failed",
        description: "Failed to save changes. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      queryClient.setQueryData(campaignsQueryKey, context?.previousData);
    },
    onSuccess: () => {
      toast({
        title: "Changes saved",
        description: "Your changes have been saved",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["campaigns"],
      });
    },
  });

  return {
    isPending,
    saveCampaignAsync,
  };
}