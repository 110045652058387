import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

const NewScriptModal = ({ isOpen, onClose, onSubmitName, scriptName: initialScriptName = '' }) => {
  const [scriptName, setScriptName] = useState(initialScriptName || "");
  const [error, setError] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setScriptName(initialScriptName || "");
      setError("");
      setTimeout(() => {
        ref.current?.focus();
      }, 200)
    }
  }, [isOpen, initialScriptName]);

  const handleCreate = (event) => {
    event.preventDefault();
    if (!scriptName) {
      setError("Script name is required");
      return;
    };

    onSubmitName(scriptName);
    setScriptName("");
    onClose();
  };

  const onChaneScriptName = (event) => {
    setScriptName(event.target.value);
    setError("");
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Script Name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleCreate}>
            <FormControl isInvalid={!!error}>
              <Input
                ref={ref}
                placeholder="Enter script name"
                value={scriptName}
                onChange={onChaneScriptName}
                autoFocus
              />
              <FormErrorMessage>{error as string}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="green" ml={3} onClick={handleCreate}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewScriptModal;
