import {
  Box,
  Button,
  Center,
  Flex,
  ModalBody,
  ModalFooter,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { pickByMode } from "../../../utils/theme";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useCampaignsState } from "../../../hooks/useCampaignsState";
import ChatComponent from "./ChatComponent";
import ScriptListItem from "./ScriptListItem";
import NewScriptModal from "./NewScriptModal";
import produce from "immer";
import { useSaveCampaignData } from '../../../hooks/useSaveCampaignData';


const SampleScript = () => {
  const { colorMode } = useColorMode();
  const { campaignId } = useParams<{ campaignId: string }>();
  const campaignData = useCampaignsState()?.campaigns?.[campaignId!];
  const { control, handleSubmit, setValue, watch, getValues, formState, reset } = useForm({
    defaultValues: {
      activeScriptId: campaignData?.promptTemplate?.activeScriptId || null,
      scripts: campaignData?.promptTemplate?.scripts || {},
    },
  });
  const { isDirty } = formState;
  const screenRef = useRef<HTMLDivElement>(null);
  const { isPending, saveCampaignAsync } = useSaveCampaignData();
  const scripts = watch("scripts");
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [openScriptId, setOpenScriptId] = useState<string | null>(scripts ? Object.keys(scripts)[0] : null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSelectScript = (scriptId: string) => {
    setOpenScriptId(scriptId);
  };

  const onPressSetAsNativeScript = (scriptId: string) => {
    setValue("activeScriptId", scriptId, {shouldDirty: true});
  };

  const onPressDeleteScript = (id: string) => {
    const newScripts = produce(scripts, (draft) => {
      delete draft[id];
    });
    setValue("scripts", newScripts, {shouldDirty: true});
    setOpenScriptId(Object.keys(newScripts)?.[0]);
  };

  const onRenameScript = (scriptId, newName) => {
    setValue("scripts", produce(scripts, (draft) => {
      draft[scriptId].name = newName;
    }), { shouldDirty: true });
  };

  const saveChanges = handleSubmit(async (data) => {
    await saveCampaignAsync({
      campaignId,
      data: {
        promptTemplate: {
          ...campaignData?.promptTemplate,
          activeScriptId: data.activeScriptId,
          scripts: data.scripts,
        },
      },
    });
    reset(getValues(), {keepValues: false, keepDirty: false})
  })

  const onPressNewScript = () => {
    onOpen();
  };

  const handleCreateNewScript = (name: string) => {
    const newScriptId = Date.now().toString();
    setValue("scripts",
      produce(scripts, (draft) => {
        draft[newScriptId] = {
          name: name || "New Script",
          messages: [
            {
              role: "user",
              content: "Hello?",
            },
          ],
        };
      }),
      {shouldDirty: true}
    );
    setOpenScriptId(newScriptId);
  };

  return (
    <>
      <ModalBody borderRadius="10px">
        <Flex  ref={screenRef}>
          <Box minW="200px" maxW="200px">
            <Text fontSize="xl" mt={4}>
              Scripts Library
              <Text fontSize="xs">({Object.keys(scripts).length} scripts)</Text>
            </Text>
            <Button
              mr={2}
              mt={2}
              isDisabled={isPending}
              variant="outline"
              colorScheme="green"
              size="sm"
              rounded="full"
              onClick={onPressNewScript}
            >
              + New Script
            </Button>
            <Box overflowY="auto" maxH="50vh" pr={5} scrollBehavior="smooth">
              {Object.entries(scripts)?.map(([scriptId, script]) => (
                <ScriptListItem
                  key={scriptId}
                  script={script}
                  scriptId={scriptId}
                  openScriptId={openScriptId}
                  onSelectScript={onSelectScript}
                  onPressSetAsNativeScript={onPressSetAsNativeScript}
                  onPressDeleteScript={onPressDeleteScript}
                  onRenameScript={onRenameScript}
                  watch={watch}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Center>
              <Text
                fontSize="14px"
                color={pickByMode(colorMode, "gray.600", "gray.400")}
                w="95%"
              >
                In this section you may include a sample script in order to
                better guide the assistant. Create a fictitious conversation
                including the behavior you want the assistant to emulate.
              </Text>
            </Center>
             {openScriptId && <ChatComponent
                control={control}
                openScriptId={openScriptId}
                editIndex={editIndex}
                key={openScriptId}
                setEditIndex={setEditIndex}
              />}
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={isPending}
          loadingText="Saving..."
          colorScheme="green"
          onClick={saveChanges}
          isDisabled={!isDirty}
        >
          Save changes
        </Button>
      </ModalFooter>
      <NewScriptModal isOpen={isOpen} onClose={onClose} onSubmitName={handleCreateNewScript} />
    </>
  );
};

export default SampleScript;
