import React, { useEffect } from "react";
import Content from "../../components/Content";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Image,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import IntegrationModal from "./IntegrationModal";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestoreDb } from "../../firebase";
import { useProfileState } from "../../hooks/useProfileState";
import { useParams } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";
import DisconnectAlert from "./DisconnectAlert";
import { IoMdOpen } from "react-icons/io";

const availableIntegrations = [
  {
    serviceType: "gohighlevel",
    name: "Go High Level",
    description: "Go High Level is a CRM and marketing automation tool.",
    link: "https://www.gohighlevel.com/",
    logo: "/logos/highlevel.png",
  },
];

const Integrations = () => {
  const [integrations, setIntegrations] = React.useState({});
  const { campaignId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const { colorMode } = useColorMode();
  const { data: profile } = useProfileState();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => setSelectedIntegration(null),
  });
  const disconnectAlertProps = useDisclosure({
    onClose: () => setSelectedIntegration(null),
  });
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<any>(null);

  const onConnect = (integration) => {
    setSelectedIntegration(integration);
    onOpen();
  };

  useEffect(() => {
    // fetch integrations from the firestore
    let unsubscribeCampaignsListener = () => {};
    const q = query(
      collection(firestoreDb, "integrations"),
      where("campaignId", "==", campaignId)
    );

    unsubscribeCampaignsListener = onSnapshot(
      q,
      (querySnapshot) => {
        const fetchedIntegrations = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedIntegrations[data.serviceType] = {
            id: doc.id,
            ...data,
          };
        });
        setIntegrations(fetchedIntegrations);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching documents:", error);
        setIntegrations({});
        setLoading(false);
      }
    );

    return () => {
      unsubscribeCampaignsListener();
    };
  }, [profile?.user?.uid, campaignId]);

  const onDisconnect = (integration) => {
    setSelectedIntegration(integration);
    disconnectAlertProps.onOpen();
  };

  return (
    <Content>
      <Box display="flex" flexGrow={1} flexDir="column">
        {loading ? (
          <Center flexGrow={1}>
            <Stack alignItems="center">
              <Spinner size="xl" />
              <Text mt={5}>Loading integrations...</Text>
            </Stack>
          </Center>
        ) : (
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "1fr 1fr",
              lg: "repeat(2, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            gap={6}
          >
            {availableIntegrations.map((integration, index) => (
              <Box
                key={index}
                padding="5"
                bg="Background"
                border={colorMode === "light" ? "1px solid #EEEFFF" : "none"}
                borderRadius="10px"
              >
                <Box>
                  <Box
                    h="150px"
                    bg="Background"
                    objectFit="contain"
                    aspectRatio={1}
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="20px"
                    margin="0 auto"
                  >
                    <Image src={integration.logo} alt={integration.name} />
                  </Box>
                  <Box>
                    <Flex alignItems="center" flexWrap="wrap">
                      <Text fontSize="lg" fontWeight="bold">
                        {integration.name}
                      </Text>
                      <IconButton
                        as="a"
                        href={integration.link}
                        target="_blank"
                        aria-label="Learn more"
                        icon={<IoMdOpen />}
                        ml={2}
                        variant="ghost"
                        size="sm"
                      />
                    </Flex>
                    <Text fontSize="sm">{integration.description}</Text>
                  </Box>
                  {integrations[integration.serviceType]?.connected ? (
                    <>
                      <Flex mt={4} alignItems="center">
                        <CheckCircleIcon color="green" />{" "}
                        <Text ml={2}>Connected</Text>
                      </Flex>
                      <Flex mt={2}>
                        <Button
                          onClick={() => onDisconnect(integration)}
                          colorScheme="red"
                        >
                          Disconnect
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <Flex mt={5}>
                      <Button
                        onClick={() => onConnect(integration)}
                        colorScheme="green"
                      >
                        Connect
                      </Button>
                    </Flex>
                  )}
                </Box>
              </Box>
            ))}
          </Grid>
        )}
      </Box>
      <IntegrationModal
        isOpen={isOpen}
        onClose={onClose}
        integration={{
          ...selectedIntegration,
          ...integrations[selectedIntegration?.serviceType],
        }}
      />
      <DisconnectAlert
        {...disconnectAlertProps}
        integration={{
          ...selectedIntegration,
          ...integrations[selectedIntegration?.serviceType],
        }}
      />
    </Content>
  );
};

export default Integrations;
