import {
  Button,
  Center,
  Flex,
  IconButton,
  ModalBody,
  ModalFooter,
  Switch,
  Text,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { pickByMode } from "../../utils/theme";
import { DeleteIcon } from "@chakra-ui/icons";
import { standardGuidelines } from "../../utils/others";
import { useParams } from "react-router-dom";
import { useCampaignsState } from "../../hooks/useCampaignsState";
import { useSaveCampaignData } from '../../hooks/useSaveCampaignData';

const CommunicationGuidelines = () => {
  const { colorMode } = useColorMode();
  const { campaignId } = useParams<{ campaignId: string }>();
  const campaignData = useCampaignsState()?.campaigns?.[campaignId!];
  const { control, handleSubmit, watch, formState: {isDirty}, getValues, reset } = useForm({
    defaultValues: {
      manualEdit: campaignData?.promptTemplate?.manual || false,
      guidelines: campaignData?.promptTemplate?.communicationGuidelines || standardGuidelines,
    },
  });
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "guidelines",
  });

  const manualEdit = watch("manualEdit");

  const {isPending, saveCampaignAsync} = useSaveCampaignData();

  const  saveChanges = handleSubmit(async (data) => {
    await saveCampaignAsync({
      campaignId,
      data: {
        promptTemplate: {
          ...campaignData?.promptTemplate,
          communicationGuidelines: data.guidelines,
          manual: data.manualEdit,
        },
      },
    });
    reset(getValues(), {keepValues: false, keepDirty: false})
  });

  return (
    <>
      <ModalBody borderRadius="10px">
        <Center>
          <Text
            fontSize="14px"
            color={pickByMode(colorMode, "gray.600", "gray.400")}
            w="95%"
          >
            In this section you can check how the representative is prompted to
            behave. The reviews you provide are used to continuously improve its
            behavior during the call. You may also prompt it yourself by
            switching to manual editing, but note that the prompt won't benefit
            from feedback improvements from reviews anymore.
          </Text>
        </Center>
        <Center margin="2%">
          <Text fontWeight={500}>Manual Editing</Text>
          <Controller
            name="manualEdit"
            control={control}
            render={({ field }) => (
              <Switch
                isChecked={field.value}
                onChange={field.onChange}
                marginTop="auto"
                marginBottom="auto"
                size="md"
                colorScheme="green"
                marginLeft="2"
              />
            )}
          />
        </Center>
        {fields.map((_, index) => {
          return (
            <Flex margin="2%" alignItems="center">
              <Text color="blue.600" fontSize="xl" fontWeight={700}>
                {index + 1}.
              </Text>
              <Controller
                name={`guidelines.${index}`}
                control={control}
                render={({ field }) => (
                  <Textarea
                    isDisabled={!manualEdit}
                    marginLeft="1%"
                    value={field.value}
                    border="1px"
                    borderColor="blue.200"
                    p="2"
                    onChange={field.onChange}
                  />
                )}
              />
              <IconButton
                isDisabled={!manualEdit}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={() => remove(index)}
                variant="ghost"
              />
            </Flex>
          );
        })}
        <Center mt="4">
          <Button
            isDisabled={!manualEdit}
            colorScheme="green"
            variant="outline"
            borderRadius="full"
            onClick={() => append("")}
          >
            + Add guideline
          </Button>
        </Center>
      </ModalBody>
      <ModalFooter>
        <Button isLoading={isPending} isDisabled={!isDirty} onClick={saveChanges} loadingText="Saving..." colorScheme="green" type="submit">
          Save changes
        </Button>
      </ModalFooter>
    </>
  );
};

export default CommunicationGuidelines;
