import {  DashboardIcon, LeadsIcon, RecordingIcon, SettingsIcon } from './assets/icons';
import Dashboard from './pages/dashboard';
import { Navigate, useParams } from 'react-router-dom';
import RecordingsPage from './pages/Recordings';
import CustomersPage from './pages/customers/index';
import ConfigsPage from './pages/Configs';
import Appointments from './pages/Appointments';
import { CalendarIcon } from '@chakra-ui/icons';
import PlaygroundPage from './pages/Playground';
import { ImLab } from 'react-icons/im';
import { AiOutlineAppstoreAdd, AiOutlineUserAdd } from "react-icons/ai";
import Integrations from './pages/integrations';
import Agents from './pages/agents';


export const RedirectToDashboard = () => {
  const { campaignId } = useParams(); // Capture the campaignId from the URL
  return <Navigate to={`/${campaignId}/dashboard`} replace />;
};


export const routes = [
  {
    name: "Dashboard",
    path: "dashboard",
    page: <Dashboard />,
    icon: <DashboardIcon />,
  },
  {
    name: "Appointments",
    path: "appointments",
    page: <Appointments />,
    icon: <CalendarIcon fontSize="xl" />,
  },
  {
    name: "Recordings",
    path: "recordings",
    page: <RecordingsPage />,
    icon: <RecordingIcon />,
  },
  {
    name: "Customers",
    path: "customers",
    page: <CustomersPage />,
    icon: <LeadsIcon />,
  },
  {
    name: "Users",
    path: "users",
    page: <Agents />,
    icon: <AiOutlineUserAdd size="28px"   />,
  },
  {
    name: "Configurations",
    path: "configurations",
    page: <ConfigsPage />,
    icon: <SettingsIcon />,
  },
  {
    name: "Integrations",
    path: "integrations",
    page: <Integrations />,
    icon: <AiOutlineAppstoreAdd size="28px" />,
  },
  {
    name: "Playground",
    path: "playground",
    page: <PlaygroundPage />,
    icon: <ImLab size="20px" />
  },
];
