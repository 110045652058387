import { getIdToken } from 'firebase/auth';
import { auth } from '../../firebase';
import { isProduction } from '..';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const fetcher = async (url: string, options: RequestInit = {}) => {
  const {headers, ...rest} = options;
  const modifiedOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...rest
  };


  if (auth.currentUser) {
    const token = await getIdToken(auth.currentUser)
    
    if (token) {
      modifiedOptions.headers!['Authorization'] = `Bearer ${token}`;
    }
  }

  if (!isProduction()) {
    modifiedOptions.headers!["ngrok-skip-browser-warning"] = "true";
  }
  
  return fetch(`${baseUrl}${url}`, modifiedOptions);
}