import { Box, Textarea, Button, IconButton, useOutsideClick } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

const EditMessage = ({ message, index, update, remove, setEditIndex }) => {
  const [editMessageContent, setEditMessageContent] = useState<string>(message.content);
  const ref = React.useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref,
    handler: () => {
      setEditIndex(-1);
    },
  })
  return (
    <Box
      boxShadow="md"
      border="2px solid #8cbbf4"
      borderRadius="lg"
      padding="2"
      maxWidth="70%"
      ref={ref}
    >
      <Textarea
        value={editMessageContent}
        onChange={(e) => setEditMessageContent(e.target.value)}
        bg=""
        borderRadius="10px"
        placeholder="Edit your message"
      />
      <Button
        size="sm"
        onClick={() => {
          update(index, { ...message, content: editMessageContent });
          setEditIndex(-1);
        }}
        marginTop="2"
      >
        Save
      </Button>
      <IconButton
        size="sm"
        aria-label="Delete"
        icon={<DeleteIcon />}
        onClick={() => remove(index)}
        variant="ghost"
        marginTop="2"
      />
    </Box>
  );
};

export default EditMessage;
