import { Stack } from "@chakra-ui/react";

const Content = ({ children }) => {
  return (
    <Stack
      w="100%"
      pb="30px"
      px={{
        base: "16px",
        md: "30px",
      }}
      m="0 auto"
      maxW="1400px"
      spacing="6"
    >
      {children}
    </Stack>
  );
};

export default Content;
