import { AddIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  Heading,
  VStack,
  Skeleton,
  SkeletonText,
  Box,
  Wrap,
  IconButton,
  Flex,
  Text,
  Spacer,
  Tooltip,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Center,
  useColorMode,
  Image,
} from "@chakra-ui/react";
import NewProjectWindow from "../components/newProjectWindow";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import { BsPeople } from "react-icons/bs";
import ManageAccountWindow from "../components/manageAccountWindow";
import CampaignCard from "../components/campaignCard";
import { signOut } from "firebase/auth";
import { useBillingModalState } from "../hooks/useBillingModalState";
import { useProfileState } from "../hooks/useProfileState";
import { pickByMode } from "../utils/theme";
import { useCampaignsState } from "../hooks/useCampaignsState";
import { useCampaignsQuery } from "../hooks/useCampaignsQuery";
import { CampaignDataInterface } from '../utils/interfaces';

export default function CampaignsPage() {
  const { setCampaigns } = useCampaignsState();
  const { data: campaigns, isLoading, error } = useCampaignsQuery<{[key: string]: CampaignDataInterface}>();
  console.log('error:', error)

  const [openNewProject, setOpenNewProject] = useState<boolean>(false);
  const [hoveredProject, setHoveredProject] = useState<string>("");
  const [openManageAccount, setOpenManageAccount] = useState<boolean>(false);
  const { setVisible: setBillingModalVisible } = useBillingModalState();
  const profile = useProfileState((state) => state.data);

  const onOpenBillingModal = () => setBillingModalVisible(true);

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (campaigns) {
      setCampaigns(campaigns || {});
    }
  }, [campaigns]);

  const isUnlimitedPlan = profile?.plan?.creditMinutes === 1000000;

  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      padding="1%"
      bg={pickByMode(colorMode, "rgba(239, 237, 240, 0.3)", "#1B1D20")}
    >
      <Box h="7vh">
        <Flex alignItems="center">
          <Link to="/">
            <Image
              cursor="pointer"
              maxH="179px"
              w="120px"
              src={colorMode === "light" ? "/logo.png" : "/logo-dark.png"}
            />
          </Link>
          <Spacer />
          {profile?.plan && (
            <Tag
              colorScheme={profile?.remainingPlanMinutes < 1 ? "red" : "gray"}
            >
              Credits:{" "}
              <Text mr={0.5} ml={2} fontWeight="semibold">
                {isUnlimitedPlan
                  ? "Unlimited"
                  : `${profile?.remainingPlanMinutes} mins`}
              </Text>
            </Tag>
          )}
          <Tooltip label="Manage Access">
            <IconButton
              onClick={() => {
                setOpenManageAccount(true);
              }}
              aria-label="Manage Access"
              icon={<BsPeople />}
              variant="ghost"
              fontSize="20px"
            />
          </Tooltip>
          <Tooltip label="Create new campaign">
            <IconButton
              onClick={() => setOpenNewProject(true)}
              aria-label="New campaign"
              icon={<AddIcon />}
              variant="ghost"
            />
          </Tooltip>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={onOpenBillingModal}>Billing</MenuItem>
              <MenuItem
                onClick={() => {
                  signOut(auth);
                }}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      {isLoading ? (
        <>
          {[3, 2].map((row, index) => (
            <Box key={index} mt={10}>
              <Skeleton height="30px" width="200px" ml="2%" />
              <Wrap padding="2%" spacing="4vw">
                {Array(row)
                  .fill(0)
                  .map((_, col) => (
                    <Box
                      key={col}
                      opacity="0.85"
                      transform="scale(1.05)"
                      cursor="pointer"
                      borderRadius="15px"
                      boxShadow="lg"
                      w="300px"
                      h="230px"
                      transition="all 0.3s ease"
                      bg="gray.200"
                    >
                      <Skeleton height="150px" borderTopRadius="15px" />
                      <Box padding="5%">
                        <SkeletonText mt="4" noOfLines={2} spacing="4" />
                      </Box>
                    </Box>
                  ))}
              </Wrap>
            </Box>
          ))}
        </>
      ) : error ? (
        <Text>Error loading campaigns</Text>
      ) : (
        <>
          {campaigns &&
            [
              ...new Set(
                Object.keys(campaigns).map(
                  (campaignId) => campaigns[campaignId].dealName
                )
              ),
            ].map((dealName, idx) => (
              <Box key={idx} mt={10}>
                <Text fontSize="2xl" fontWeight={600} ml="2%">
                  {dealName}
                </Text>
                <Wrap padding="2%" spacing="4vw">
                  {Object.keys(campaigns)
                    .filter(
                      (campaignId) =>
                        campaigns[campaignId].dealName === dealName
                    )
                    .map((campaignId) => (
                      <CampaignCard
                        campaign={campaigns[campaignId]}
                        setHoveredProject={setHoveredProject}
                        hoveredProject={hoveredProject}
                      />
                    ))}
                </Wrap>
              </Box>
            ))}
          {!Object.keys(campaigns || {}).length && (
            <Center flexGrow={1}>
              <VStack spacing={4} align="center">
                <Heading>No Campaigns Yet</Heading>
                <Text>Start your first campaign and boost your business</Text>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => setOpenNewProject(true)}
                >
                  Create Campaign
                </Button>
              </VStack>
            </Center>
          )}
        </>
      )}
      <NewProjectWindow
        openNewProject={openNewProject}
        setOpenNewProject={setOpenNewProject}
      />
      {auth.currentUser && openManageAccount && (
        <ManageAccountWindow
          openManageAccount={openManageAccount}
          setOpenManageAccount={setOpenManageAccount}
          auth={auth}
          allCampaigns={campaigns!}
        />
      )}
    </Box>
  );
}
