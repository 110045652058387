import {
  ChakraProvider,
  Spinner,
  Center,
  ColorModeScript,
} from "@chakra-ui/react";
import { auth, firestoreDb } from "./firebase";
import AuthenticationPage from "./pages/Login";
import React, { useEffect, useState } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { useProfileState } from "./hooks/useProfileState";
import { QueryClientProvider } from "@tanstack/react-query";
import theme from "./utils/theme";
import Main from "./Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CampaignsPage from "./pages/Campaigns";
import { RedirectToDashboard, routes } from "./routes";
import { queryClient } from "./utils/queryClient";

function LoadingIndicator() {
  return (
    <Center h="100vh">
      <Spinner size="xl" />
    </Center>
  );
}

export default function App() {
  const [userAuth, setUserAuth] = useState<boolean | null>(null);
  const { set: setProfile } = useProfileState();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribeFirestore = () => {};
    let unsubscribeUserInfoListener = () => {};
    let unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setUserAuth(true);
        const userRef = doc(firestoreDb, "users", user.uid);
        setProfile({ user: user });
        unsubscribeUserInfoListener = onSnapshot(
          userRef,
          (userInfoDoc) => {
            if (!userInfoDoc.exists) {
              return;
            }
            setProfile({
              user,
              ...userInfoDoc.data(),
            });
            setLoading(false);
          },
          (error) => {
            setLoading(false);
          }
        );
      } else {
        setUserAuth(false);
        setLoading(false);
        setProfile(null);
      }
    });

    // Cleanup function
    return () => {
      unsubscribeFirestore();
      unsubscribeAuth();
      unsubscribeUserInfoListener();
    };
  }, [setProfile]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        {loading ? (
          <LoadingIndicator />
        ) : !userAuth ? (
          <AuthenticationPage />
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path=":campaignId" element={<Main />}>
                <Route index element={<RedirectToDashboard />} />
                {routes.map((item) => (
                  <Route
                    index={item.path === "dashboard"}
                    key={item.name}
                    path={item.path}
                    element={item.page}
                  />
                ))}
              </Route>
              <Route path="/" element={<CampaignsPage />} />
            </Routes>
          </BrowserRouter>
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
}
