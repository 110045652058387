import {
  Modal,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  InputGroup,
  useToast,
  InputRightAddon,
  Box,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CopyIcon } from "@chakra-ui/icons";
import { useMutation } from '@tanstack/react-query';
import { fetcher } from '../../utils/api/fetcher';
import { useEffect, useRef, useState } from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

function getWebhookUrl(campaignId: string) {
  return `${process.env.REACT_APP_BACKEND_URL}/integrations/gohighlevel/${campaignId}`;
}

const IntegrationModal = ({ isOpen, onClose, integration }) => {
  const { campaignId } = useParams();
  const toast = useToast();
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const url = getWebhookUrl(campaignId!);
  const { copy } = useCopyToClipboard();
  const integrationRef = useRef<any>(integration);
  integrationRef.current = integration;

  const {isPending, data, mutate} = useMutation({
    mutationFn: async () => {
      const response = await fetcher(`/integrations/token`, {
        method: 'POST',
        body: JSON.stringify({
          service_type: integration.serviceType,
          campaign_id: campaignId
        })
      })

      return response.json();
    }
  })

  const checkStatus = async () => {
    setIsCheckingStatus(true);

    setTimeout(() => {
      if (!integrationRef.current?.connected) {
        toast({
          description: 'Integration not connected yet.',
          status: "warning",
        })
      }
      setIsCheckingStatus(false);
    }, 3000)
  };

  const {apikey} = data || {};

  useEffect(() => {
    if (isOpen) {
      mutate();
    }
  }, [isOpen, mutate])

  useEffect(() => {
    if (integration?.connected && isOpen) {
      toast({
        description: 'Integration connected successfully.',
        status: "success",
      });
      onClose();
    }
  }, [integration?.connected, onClose, isOpen, toast])

  const onHandleCopyUrl = () => {
    copy(url);
    toast({
      title: "Copied.",
      description: "The webhook url has been copied to your clipboard.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const onHandleCopyApiKey = () => {
    copy(apikey);
    toast({
      title: "Copied.",
      description: "The api key has been copied to your clipboard.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect {integration?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="semibold" mb={3}>Follow the steps below:</Text>
            <Text>1. Create a new action in GHL automation</Text>
            <Text  mt={5}>2. Search for <b>"Custom Webhook"</b> and click on it</Text>
            <Text  mt={5}>3. Copy the following url and paste it into the <b>"URL"</b> field</Text>
            <InputGroup>
              <Input isReadOnly value={url} />
              <InputRightAddon
                cursor="pointer"
                onClick={onHandleCopyUrl}
                sx={{
                  _hover: {
                    opacity: 0.8,
                  },
                }}
              >
                <CopyIcon />
              </InputRightAddon>
            </InputGroup>
          </Box>
          <Box mt={5}>
            <Text>4. Click on <b>"Add item"</b> under <b>"QUERY PARAMETERS"</b> and enter <b>apikey</b> into <b>"Key"</b> field and copy & paste the string below into the <b>"Value"</b> field</Text>
            {
              isPending ? (
                <Center mt={5}>
                  <Text display="flex" alignItems="center"><Spinner mr={2} /> Generating api key </Text>
                </Center>
              ): (
                <InputGroup>
                  <Input isReadOnly value={apikey}  />
                  <InputRightAddon
                    cursor="pointer"
                    onClick={onHandleCopyApiKey}
                    sx={{
                      _hover: {
                        opacity: 0.8,
                      },
                    }}
                  >
                    <CopyIcon />
                  </InputRightAddon>
                </InputGroup>
              )
            }
          </Box>
          <Text mt={5}>5. Hit <b>Save action</b> and then save the workflow</Text>
          <Text mt={5}>6. In order to verify integration, please test the trigger you have set up in GHL automation</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={checkStatus} isLoading={isCheckingStatus} colorScheme="green">Check Status</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default IntegrationModal;
