import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { isProduction } from "./utils";

const firebaseConfig = {
  staging: {
    apiKey: "AIzaSyCeEMh_xSr8KSlux0nqxnFU09NC_K1nd1A",
    authDomain: "callbase-ai-staging.firebaseapp.com",
    projectId: "callbase-ai-staging",
    storageBucket: "callbase-ai-staging.appspot.com",
    messagingSenderId: "81693264776",
    appId: "1:81693264776:web:bfb9a459ecadcd98d5083d",
    measurementId: "G-H4KF918CKC"
  },
  production: {
    apiKey: "AIzaSyC_9CLcT5FrWYIGZqXW3FOgj01FksG_3Qw",
    authDomain: "callbase-ai.firebaseapp.com",
    projectId: "callbase-ai",
    storageBucket: "callbase-ai.appspot.com",
    messagingSenderId: "92837817460",
    appId: "1:92837817460:web:7e278e381ae43b97953521",
    measurementId: "G-762ERWT3KJ"
  },
};

// Initialize Firebase
const app = initializeApp(
  isProduction() ? firebaseConfig.production : firebaseConfig.staging
);

const analytics = getAnalytics(app);
export const db = getDatabase(app);
export const firestoreDb = getFirestore(app);
// connectFirestoreEmulator(firestoreDb, '127.0.0.1', 8080);
export const auth = getAuth(app);
export const storage = getStorage(app);
