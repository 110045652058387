import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { pickByMode } from "../../../utils/theme";
import {
  EditIcon,
  EmailIcon,
  InfoIcon,
  PhoneIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { IoIosSave } from "react-icons/io";
import { doc, updateDoc } from "firebase/firestore";
import { firestoreDb } from "../../../firebase";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { ImProfile } from 'react-icons/im';

const EditableField = ({
  editable,
  icon,
  value,
  placeholder,
  onChange,
  disabled,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      fontSize="lg"
      fontWeight="500"
      bg={pickByMode(colorMode, "blue.50", "gray.800")}
      borderRadius="10px"
      px={3}
      py={1}
      display="flex"
      alignItems="center"
      minH={"50px"}
      border={editable ? "1px solid #90cdf4" : "none"}
    >
      {icon}
      {editable ? (
        <Input
          border="none"
          focusBorderColor="transparent"
          value={value}
          fontSize={"lg"}
          disabled={disabled}
          fontWeight={"500"}
          padding={0}
          ml={2}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <Text ml={2}>{value || `-`}</Text>
      )}
    </Box>
  );
};

const renderInputs = (
  editable,
  updatedCustomer,
  setUpdatedCustomer,
  isSavingProfile
) => {
  const fields = [
    {
      icon: <ImProfile color={'#3182ce'} />,
      value: updatedCustomer.name,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, name: value }),
      placeholder: "Name",
    },
    {
      icon: <EmailIcon color="blue.500" />,
      value: updatedCustomer.email,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, email: value }),
      placeholder: "Email",
    },
    {
      icon: <PhoneIcon color="blue.500" />,
      value: updatedCustomer.phone_number,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, phone_number: value }),
      placeholder: "Phone Number",
    },
    {
      icon: <InfoIcon color="blue.500" />,
      value: updatedCustomer.address,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, address: value }),
      placeholder: "Address",
    },
    {
      icon: <EditIcon color="blue.500" />,
      value: updatedCustomer.product,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, product: value }),
      placeholder: "Product",
    },
    {
      icon: <StarIcon color="blue.500" />,
      value: updatedCustomer.audience,
      onChange: (value) =>
        setUpdatedCustomer({ ...updatedCustomer, audience: value }),
      placeholder: "Audience",
    },
  ];

  return fields.map((field, idx) => (
    <EditableField
      key={idx}
      editable={editable}
      icon={field.icon}
      value={field.value}
      disabled={isSavingProfile}
      placeholder={field.placeholder}
      onChange={field.onChange}
    />
  ));
};

const Profile = ({ selectedCustomer, setSelectedCustomer }) => {
  const [updatedCustomer, setUpdatedCustomer] = useState(selectedCustomer);
  const [editable, setEditable] = useState(false);
  const toast = useToast();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { mutateAsync: saveProfileAsync, isPending: isSavingProfile } =
    useMutation({
      mutationFn: (data: any) => {
        const customerDocRef = doc(
          firestoreDb,
          "campaigns",
          campaignId!,
          "customers",
          selectedCustomer.id
        );
        return updateDoc(customerDocRef, data);
      },
    });

  async function editCustomer(data) {
    if (!data.phone_number || !data.product || !data.name) {
      toast({
        title: "Validation Error",
        description: "Name, Phone number and product fields are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      !selectedCustomer ||
      JSON.stringify(data) === JSON.stringify(selectedCustomer)
    ) {
      toast({
        title: "Customer updated",
        description: "Customer updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return true;
    }
    await saveProfileAsync(data);

    setSelectedCustomer(data);
    toast({
      title: "Customer updated",
      description: "Customer updated successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    return true;
  }

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize="20px"
          fontWeight={700}
          color="gray.400"
          marginBottom="1%"
        >
          Customer Information
        </Text>
        {editable ? (
          <Button
            colorScheme="green"
            onClick={async () => {
              const saved = await editCustomer(updatedCustomer);
              if (saved) {
                setEditable(!editable);
              }
            }}
            disabled={isSavingProfile}
          >
            <IoIosSave size={18} />
            <Text ml={2}>{isSavingProfile ? "Saving..." : "Save"}</Text>
          </Button>
        ) : (
          <Button onClick={() => setEditable(!editable)}>
            <EditIcon mr={2} />
            Edit
          </Button>
        )}
      </Flex>
      <VStack spacing={4} align="stretch" mt={2}>
        {renderInputs(
          editable,
          updatedCustomer,
          setUpdatedCustomer,
          isSavingProfile
        )}
      </VStack>
    </Box>
  );
};

export default Profile;
