import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ref as refStorage, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import { useParams } from "react-router-dom";
import { useCampaignsState } from "../../../hooks/useCampaignsState";

const CallModalInfo = ({
  openCallInfo,
  setOpenCallInfo,
  allCustomerCalls,
  selectedCustomer,
}) => {
  const { campaignId } = useParams();
  const campaignData = useCampaignsState()?.campaigns?.[campaignId!];
  const [callAudioUrl, setCallAudioUrl] = useState<string | null>(null);

  useEffect(() => {
    if (openCallInfo.length > 0) {
      const audioRef = refStorage(storage, `recordings/${openCallInfo}.mp3`);
      getDownloadURL(audioRef)
        .then((url) => {
          setCallAudioUrl(url);
        })
        .catch((error) => {
          setCallAudioUrl(null);
        });
    }
  }, [openCallInfo]);

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={openCallInfo.length > 0}
      onClose={() => {
        setOpenCallInfo("");
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Call Info</ModalHeader>
        <ModalBody padding="5%">
          <Center>
            <audio controls src={callAudioUrl!}>
              <a href={callAudioUrl!}> Download audio </a>
            </audio>
          </Center>
          <Divider marginTop="3%" />
          {allCustomerCalls &&
            allCustomerCalls[openCallInfo] &&
            allCustomerCalls[openCallInfo].dialogue && (
              <Box overflowY="auto" h="43vh" padding="2%">
                {allCustomerCalls[openCallInfo].dialogue.map(
                  (message, index) => {
                    if (message.role !== "system") {
                      return (
                        <Flex
                          marginTop="2%"
                          key={index}
                          justifyContent={
                            message.role === "user" ? "flex-end" : "flex-start"
                          }
                        >
                          {message.role === "assistant" && (
                            <Avatar
                              name={
                                message.role === "user"
                                  ? selectedCustomer.name
                                  : campaignData?.repName
                              }
                              size="sm"
                              marginRight={message.role === "user" ? "0" : "2"}
                              marginLeft={message.role === "user" ? "2" : "0"}
                            />
                          )}
                          <Box
                            bg={
                              message.role === "assistant" ? "blue.100" : "white"
                            }
                            boxShadow="lg"
                            border={"2px solid #c0dbf6"}
                            borderRadius="10px"
                            padding="2%"
                            maxWidth="70%"
                          >
                            <Text fontSize={13} fontWeight={600} color="gray.600">
                              {message.role === "user"
                                ? selectedCustomer.name
                                : campaignData?.repName}
                            </Text>
                            <Text color="gray.600" fontSize={12}>
                              {message.content}
                            </Text>
                          </Box>
                          {message.role === "user" && (
                            <Avatar
                              name={selectedCustomer.name}
                              size="sm"
                              marginLeft={2}
                            />
                          )}
                        </Flex>
                      );
                    }
                    return null;
                  }
                )}
              </Box>
            )}
          <Text
            textAlign="center"
            marginTop="3%"
            fontWeight={500}
            color="gray.600"
            fontSize="12px"
            bg="blue.100"
            padding="2%"
            borderRadius="10px"
            border="1px solid #90cdf4"
          >
            {openCallInfo.length > 0 &&
            allCustomerCalls[openCallInfo].evaluation
              ? allCustomerCalls[openCallInfo].evaluation.summary
              : "Under Analysis..."}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CallModalInfo;
