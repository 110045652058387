import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { fetcher } from '../../utils/api/fetcher'

const DisconnectAlert = ({isOpen, onClose, integration}: any) => {
  const cancelRef = React.useRef<any>()
  const toast = useToast();
  
  const {isPending, mutate: disconnect} = useMutation({
    mutationFn: async () => {
      const response = await fetcher(`/integrations/disconnect`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: integration.id,
        })
      })

      const data = await response.json();

      if (!response.ok) {
        const error: any = new Error();
        error.response = data;
        throw error;
      }

      return data;
    },
    onSuccess: (response) => {
      if (response?.ok) {
        toast({
          description: 'Integration disconnected successfully.',
          status: "success",
        })
        onClose();
      }
    },
    onError: (error: any) => {
      toast({
        description: error?.response?.detail || 'An error occurred while disconnecting the integration.',
        status: "error",
      })
    }
  })

  return (
    <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={!isPending}
        closeOnEsc={!isPending}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Disconnect {integration?.name}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => disconnect()} ml={3} isLoading={isPending}>
                Yes, disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
  )
}

export default DisconnectAlert