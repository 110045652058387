
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetcher } from '../utils/api/fetcher';

export const campaignsQueryKey = ["campaigns"];
export const useCampaignsQuery = <T>(options?: Omit<UseQueryOptions, 'queryKey'>) => useQuery<T>({
    queryKey: campaignsQueryKey,
    queryFn: async () => {
      const response = await fetcher("/campaigns/list");
      const campaigns = await response.json();
      return campaigns?.reduce((acc, campaign) => {
        acc[campaign.id] = campaign;
        return acc;
      }, {});
    },
    ...options as any,
  })
