import {
  Box,
  Button,
  Flex,
  useColorMode,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import { useState } from "react";
import dayjs from "dayjs";
import NewAgentModal from './NewAgentModal';
import Content from "../../components/Content";
import { fetcher } from '../../utils/api/fetcher';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import RemoveAgentDialog from './RemoveAgentDialog';

const Agents = () => {
  const { colorMode } = useColorMode();
  const { campaignId } = useParams();
  
  const { data: agents, isLoading, error } = useQuery({
    queryKey: ['agents', campaignId],
    queryFn: async () => {
      const response = await fetcher(`/agents/list?campaign_id=${campaignId}`);
      return response.json();
    },
    enabled: !!campaignId,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const [userToRemove, setUserToRemove] = useState<string | null>(null);

  const removeUser = (email: string) => {
    setUserToRemove(email);
    onConfirmOpen();
  };

  const renderLoading = () => (
    <Center height="300px">
      <Spinner size="xl" />
    </Center>
  );

  const renderError = () => (
    <Center height="300px">
      <Text fontSize="2xl" fontWeight="bold" color="red.500">
        Failed to load agents.
      </Text>
    </Center>
  );

  const renderNoAgents = () => (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="300px"
      borderRadius="md"
      p="6"
    >
      <Text fontSize="xl" fontWeight="bold" mb="4" color="gray.600">
        Invite your first user.
      </Text>
      <Button
        colorScheme="green"
        size="md"
        onClick={() => setIsModalOpen(true)}
      >
        + Add User
      </Button>
    </Flex>
  );

  const renderAgentsTable = () => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Email</Th>
          <Th width="250px">Invited At</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {agents && agents.map((user, index) => (
          <Tr key={index}>
            <Td>
              <Flex alignItems="center">{user.email}</Flex>
            </Td>
            <Td width="250px">{dayjs(user.invitedAt).format("MMMM D, YYYY")}</Td>
            <Td>
              <Menu>
                <MenuButton as={IconButton} icon={<FaEllipsisH />} />
                <MenuList>
                  <MenuItem
                    icon={<FaTrash />}
                    onClick={() => removeUser(user.email)}
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <>
      <Content>
        <Box
          padding="5"
          bg="Background"
          border={colorMode === "light" ? "1px solid #EEEFFF" : "none"}
          borderRadius="10px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="2xl" fontWeight="bold">
              Users
            </Text>
            {agents && agents.length > 0 && (
              <Button colorScheme="green" onClick={() => setIsModalOpen(true)}>
                + Add User
              </Button>
            )}
          </Flex>
          <Box mt="5">
            {isLoading && renderLoading()}
            {error && renderError()}
            {!isLoading && !agents?.length && renderNoAgents()}
            {!isLoading && agents?.length > 0 && renderAgentsTable()}
          </Box>
        </Box>
      </Content>
      <NewAgentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        campaignId={campaignId}
      />
      <RemoveAgentDialog
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        campaignId={campaignId}
        userToRemove={userToRemove}
        setUserToRemove={setUserToRemove}
      />
    </>
  );
};

export default Agents;
