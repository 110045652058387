import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import EditMessage from "./EditMessage";
import { useFieldArray } from "react-hook-form";

import { IoMdPaperPlane } from "react-icons/io";
import { BsStars } from "react-icons/bs";
import { useState } from "react";
import fetchGenerateAutomaticResponse from '../../../helpers/fetchGenerateAutomaticResponse';
import { useParams } from 'react-router-dom';

const ChatComponent = ({ 
  openScriptId,
  editIndex, 
  setEditIndex,
  control,
}) => {
  const [textValue, setTextValue] = useState<string>("");
  const { campaignId } = useParams<{ campaignId: string }>();
  const { fields, append, remove, update } = useFieldArray<any, any, any>({
    control,
    name: `scripts.${openScriptId}.messages`,
  });
  const [loading, setLoading] = useState<boolean>(false);
  
  const generateResponse = () => {
    setLoading(true);
    fetchGenerateAutomaticResponse(fields, campaignId!).then(
      (response) => {
        setTextValue(response?.response);
        setLoading(false);
      }
    );
  }

  return (
    <>
      {fields?.length > 0 && (
        <>
          <Box
            w="full"
            overflowY="auto"
            h="43vh"
            padding="4"
            marginTop="4"
            border="1px solid gray"
            borderRadius="md"
          >
            {fields.map((message, index) => (
              <Flex
                key={index}
                justifyContent={
                  message.role === "user" ? "flex-end" : "flex-start"
                }
                marginTop="2"
              >
                {message.role === "assistant" && (
                  <Avatar src="logo.ico" size="sm" marginRight="2" />
                )}
                {editIndex === index ? (
                  <EditMessage
                    message={message}
                    index={index}
                    update={update}
                    remove={remove}
                    setEditIndex={setEditIndex}
                  />
                ) : (
                  <Tooltip label="Click to edit">
                    <Box
                      onClick={() => setEditIndex(index)}
                      cursor="pointer"
                      bg={message.role === "assistant" ? "blue.100" : "white"}
                      boxShadow="md"
                      border={editIndex === index ? "2px solid #8cbbf4" : ""}
                      borderRadius="lg"
                      padding="2"
                      maxWidth="70%"
                    >
                      <Text fontSize="sm" fontWeight="bold" color="gray.600">
                        {message.role === "user" ? "Customer" : "Assistant"}
                      </Text>
                      <Text color="gray.600" fontSize="xs">
                        {message.content}
                      </Text>
                    </Box>
                  </Tooltip>
                )}
                {message.role === "user" && (
                  <Avatar
                    name="Customer"
                    size="sm"
                    marginLeft="2"
                    bg="blue.100"
                  />
                )}
              </Flex>
            ))}
          </Box>
          <Flex>
            <Textarea
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              bg={""}
              borderRadius="10px"
              disabled={loading}
              marginTop="2%"
              placeholder="Add a response to your sample script"
            />
            <Flex
              direction="column"
              margin="auto"
              marginLeft="1%"
              marginRight="1%"
            >
              {textValue !== "" ? (
                <IconButton
                  onClick={() => {
                    append({
                      role: fields.length % 2 === 1 ? "assistant" : "user",
                      content: textValue,
                    });
                    setTextValue("");
                  }} 
                  aria-label="Add response"
                  icon={<IoMdPaperPlane />}
                  variant="outline"
                  borderRadius="20px"
                />
              ) : (
                <Tooltip label="Generate automatic response" hasArrow>
                  <IconButton
                    isLoading={loading}
                    onClick={() => {
                      generateResponse();
                    }}
                    aria-label="Generate response"
                    icon={<BsStars />}
                    borderRadius="20px"
                    colorScheme="purple"
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default ChatComponent;
