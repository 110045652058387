import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { ImContrast } from "react-icons/im";
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import { Link, NavLink, Navigate, Outlet } from "react-router-dom";
import { auth } from "./firebase";
import { pickByMode } from "./utils/theme";
import { signOut } from "firebase/auth";
import { FaSignOutAlt } from "react-icons/fa";
import { routes } from "./routes";
import { UserIcon } from './assets/icons';
import { useCampaignsState } from './hooks/useCampaignsState';
import { useCampaignsQuery } from './hooks/useCampaignsQuery';
import { useEffect } from 'react';

const Sidebar = ({ routes, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Box w="64" h="100%" bg="Background" left="0px" top="0px" {...props}>
      <Center h="100px">
        <Link to="/">
          <Image
            cursor="pointer"
            maxH="179px"
            w="100px"
            src={colorMode === "light" ? "/logo.png" : "/logo-dark.png"}
          />
        </Link>
      </Center>
      <Divider w="90%" marginLeft="5%" mb="74px" />
      <Stack spacing="4">
        {routes.map((item, index) => (
          <SidebarButton
            item={item}
            key={index}
            closeSidebar={props?.closeSidebar}
          />
        ))}
      </Stack>
    </Box>
  );
};

const SidebarButton = ({ item, closeSidebar }) => {
  const { colorMode } = useColorMode();

  const handleClick = () => {
    if (typeof closeSidebar === "function") closeSidebar();
  };

  return (
    <NavLink to={item.path} onClick={handleClick}>
      {({ isActive }) => (
        <Flex
          flexDirection="row"
          alignItems="center"
          paddingLeft="40px"
          h="44px"
          marginRight="30px"
          {...(isActive
            ? {
                bg: "brand.primary_23",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                color: pickByMode(colorMode, "brand.primary", "white"),
                fontWeight: 600,
              }
            : {})}
        >
          <Box w={"24px"} h={"24px"} display="flex" justifyContent="center" alignItems="center">
            {item.icon}
          </Box>
          <Text ml="20px" size="14px">
            {item.name}
          </Text>
        </Flex>
      )}
    </NavLink>
  );
};

export default function Main() {
  // TODO: instead of fetching all campaigns, fetch only the loaded campaign
  const campaignsCache = useCampaignsState();
  const { data: campaigns, isLoading, error} = useCampaignsQuery();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    campaignsCache.setCampaigns(campaigns);
  }, [campaigns]);

  const logout = async () => {
    signOut(auth);
  };

  if (error) {
    return <Navigate to="/" />;
  }

  if (!campaignsCache?.campaigns || isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }


  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <Sidebar w="100%" routes={routes} closeSidebar={onClose} />
        </DrawerContent>
      </Drawer>
      <Flex flex={1} h="100%">
        <Sidebar
          routes={routes}
          display={["none", "none", "none", "block"]}
          position="fixed"
        />
        <Flex
          w="100%"
          flexDirection="column"
          ml={["0", "0", "0", "64"]}
          overflowY="auto"
        >
          <Flex
            p={{
              base: "16px",
              md: "30px",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              aria-label="Open menu"
              icon={<HamburgerIcon />}
              display={["flex", "flex", "flex", "none"]}
              onClick={onOpen}
            />
            {/* <Text
              fontSize={{
                base: "lg",
                md: "x-large",
              }}
              color={pickByMode(colorMode, "brand.primary", "white")}
              fontWeight="400"
            >
              Good Morning,{" "}
              <Text display="inline" fontWeight="600" as="span">
                Derek
              </Text>
            </Text> */}
            <Spacer />
            <Menu>
              <MenuButton>
                <Center
                  cursor="pointer"
                  borderRadius="15px"
                  bg={pickByMode(colorMode, "brand.primary", "black")}
                  h="40px"
                  w="40px"
                >
                  <UserIcon
                    fill={pickByMode(
                      colorMode,
                      "white",
                      "var(--chakra-colors-brand-primary)"
                    )}
                  />
                </Center>
              </MenuButton>
              <MenuList>
                <MenuItem icon={<ImContrast />} onClick={toggleColorMode}>
                  {colorMode === "light" ? "Dark Mode" : "Light Mode"}
                </MenuItem>
                <MenuItem
                  color="red.500"
                  icon={<FaSignOutAlt />}
                  onClick={logout}
                >
                  Log out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex flex={1}>
            <Outlet /> {/* This is where the child routes will be rendered */}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
